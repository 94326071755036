.dialog {
  position: fixed;
  z-index: 200;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #000000c2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 16px;
  width: 100%;
}

.dialog h1 {
  color: var(--gry-000, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Bai Jamjuree";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
}

.dialog .container {
  background-color: #1c1c1e;
  padding: 24px 32px;
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
}

.dialog .container div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dialog button {
  border-radius: 8px;
  background-color: #ff0101;
  display: flex;
  width: 100%;
  max-width: 200px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Bai Jamjuree";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  outline: none;
  border: none;
}

.dialog button svg {
  width: 18px;
  height: 18px;
  animation: rotate 0.7s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}

.dialog button:disabled {
  cursor: not-allowed;
  background-color: #949494;
}

.dialog button.close {
  background-color: rgb(0, 123, 255);
}

.dialog button.close:disabled {
  background-color: rgb(0, 123, 255);
}

@media screen and (max-width: 221px) {
  .dialog .container div {
    flex-direction: column;
  }
}
