$primary: #0099cc;
.detail-container {
  padding: 20px 150px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .single-rug-detail {
    display: flex;
    height: 600px;
    flex-direction: row;
    padding: 10px;
    background-color: #fff;
    border-radius: 3px;
    width: fit-content;
    .image {
      height: 100%;
      .rug-image {
        width: 396px;
        height: fit-content;
        object-fit: contain !important;
      }
      // .rug-image {
      //   object-fit: contain;
      //   height: fit-content;
      //   width: 320px;
      // }
    }
    .detail {
      width: 100%;
      padding: 0px 40px;
      .title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: rgb(70, 70, 70);
        .rugName {
          font-size: 24px;
          font-weight: 400;
        }
        .addtofav {
          float: right;
          font-weight: 300;
          .fav {
            cursor: pointer;
          }
        }
      }
      .horizontal-divider {
        margin-top: 10px;
        color: rgb(221, 221, 221);
        border: none;
        border-bottom: 2px solid rgb(223, 223, 223);
      }
      .design {
        display: flex;
        gap: 40px;
        padding-top: 10px;
        .type {
          color: rgb(80, 80, 80);
          font-weight: 400;
        }
        .value {
          color: $primary;
        }
      }
      .colors {
        display: flex;
        gap: 50px;
        padding-top: 10px;
        .type {
          color: rgb(80, 80, 80);
          font-weight: 400;
        }
        .value {
          color: #4f504f;
          font-weight: 400;
        }
        .color-image {
          display: flex;
          gap: 8px;

          flex-direction: column;
          .designImages {
            display: flex;
            gap: 20px;
            flex-direction: row;

            .image {
              cursor: pointer;
              border-radius: 3px;
              height: 200px;
              width: 140px;
            }
            .active {
              border-radius: 3px;
              border: 2px solid white;
              box-shadow: 0 0 0 4px $primary;
            }
          }
        }
      }
      .room-design {
        display: flex;
        width: 100%;
        height: 100px;
        justify-content: flex-start;
        align-items: flex-start;
        .view-btn {
          margin-top: 10px;
          padding: 8px 20px;
          background-color: $primary;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
.homeLayoutImage {
  height: 600px;
  width: fit-content;
  @media screen and (max-width: 1250px) {
    height: 500px !important;
  }
  @media screen and (max-width: 950px) {
    height: 450px !important;
  }
  @media screen and (max-width: 900px) {
    height: 400px !important;
  }
  @media screen and (max-width: 775px) {
    height: 350px !important;
  }
  @media screen and (max-width: 675px) {
    height: 300px !important;
  }
  @media screen and (max-width: 575px) {
    height: 250px !important;
  }
  @media screen and (max-width: 475px) {
    height: 200px !important;
  }
  @media screen and (max-width: 375px) {
    height: 200px !important;
  }
}
.no-room-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media screen and (max-width: 395px) {
    padding: 20px !important;
  }
}
@media screen and (min-width: 1800px) {
  .detail-container {
    padding: 20px 150px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .single-rug-detail {
      display: flex;
      height: 800px;
      flex-direction: row;
      padding: 10px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        height: 100%;
        .rug-image {
          width: 530px;
          height: fit-content;
          object-fit: contain !important;
        }
        // .rug-image {
        //   object-fit: contain;
        //   height: fit-content;
        //   width: 320px;
        // }
      }
      .detail {
        width: 100%;
        padding: 0px 40px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 40px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 50px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;
  
            flex-direction: column;
            .designImages {
              display: flex;
              gap: 20px;
              flex-direction: row;
  
              .image {
                cursor: pointer;
                border-radius: 3px;
                height: 220px;
                width: 140px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
        .room-design {
          display: flex;
          width: 100%;
          height: 100px;
          justify-content: flex-start;
          align-items: flex-start;
          .view-btn {
            margin-top: 10px;
            padding: 8px 20px;
            background-color: $primary;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1480px) {
  .detail-container {
    padding: 20px 120px;

    margin-bottom: 20px;
    .single-rug-detail {
      display: flex;
      height: 550px;
      flex-direction: row;
      padding: 10px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        height: 100%;
        .rug-image {
          object-fit: contain !important;
          height: fit-content;
          width: 362px;
        }
      }
      .detail {
        width: 100%;
        padding: 0px 40px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 40px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 50px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;

            flex-direction: column;
            .designImages {
              display: flex;
              gap: 20px;
              flex-direction: row;
              .image {
                cursor: pointer;
                border-radius: 3px;
                height: 190px;
                width: 130px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1390px) {
  .detail-container {
    padding: 20px 120px;

    margin-bottom: 20px;
    .single-rug-detail {
      display: flex;
      height: 541px;
      flex-direction: row;
      padding: 10px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        height: 100%;
        .rug-image {
          object-fit: contain !important;
          height: fit-content;
          width: 355px;
        }
      }
      .detail {
        width: 100%;
        padding: 0px 40px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 40px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 50px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;

            flex-direction: column;
            .designImages {
              display: flex;
              gap: 20px;
              flex-direction: row;
              .image {
                cursor: pointer;
                border-radius: 3px;
                height: 180px;
                width: 125px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1281px) {
  .homeLayoutImage {
    height: 500px;
    width: fit-content;
    object-fit: contain;
  }
  .no-room-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  .detail-container {
    padding: 20px 120px;

    margin-bottom: 20px;
    .single-rug-detail {
      display: flex;
      height: 535px;
      flex-direction: row;
      padding: 10px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        height: 100%;
        .rug-image {
          object-fit: contain;
          height: 100%;
          width: 350px;
        }
      }
      .detail {
        width: 100%;
        padding: 0px 40px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 40px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 50px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;

            flex-direction: column;
            .designImages {
              display: flex;
              gap: 20px;
              flex-direction: row;
              .image {
                cursor: pointer;
                border-radius: 3px;
                height: 175px;
                width: 120px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .detail-container {
    padding: 20px 120px;

    margin-bottom: 20px;
    .single-rug-detail {
      display: flex;
      height: 525px;
      flex-direction: row;
      padding: 10px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        height: 100%;
        .rug-image {
          object-fit: contain !important;
          height: 100%;
          width: 345px;
        }
      }
      .detail {
        width: 100%;
        padding: 0px 40px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 40px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 50px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;

            flex-direction: column;
            .designImages {
              display: flex;
              gap: 20px;
              flex-direction: row;
              .image {
                cursor: pointer;
                border-radius: 3px;
                height: 170px;
                width: 120px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .detail-container {
    padding: 20px 120px;

    margin-bottom: 20px;
    .single-rug-detail {
      display: flex;
      height: 520px;
      flex-direction: row;
      padding: 10px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        height: fit-content;
        .rug-image {
          background-size: contain !important;

          height: fit-content;
          width: 340px;
        }
      }
      .detail {
        width: 100%;
        padding: 0px 40px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 40px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 50px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;

            flex-direction: column;
            .designImages {
              display: flex;
              gap: 15px;
              flex-direction: row;
              .image {
                cursor: pointer;
                height: 150px;
                width: 110px;
                border-radius: 3px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1076px) {
  .detail-container {
    padding: 10px 18px;
    height: fit-content;

    margin-bottom: 20px;
    margin-top: 20px;
    .single-rug-detail {
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;
      flex-direction: column;
      padding: 0px 0px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        overflow: hidden;

        .rug-image {
          padding: 10px 10px;
          object-fit: cover !important;
          height: 650px;
          // width: 290px;
          width: 100%;
        }
      }
      .detail {
        width: 100%;
        padding: 10px 10px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 30px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 30px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;
            flex-direction: column;
            .designImages {
              display: flex;
              gap: 15px;
              flex-direction: row;
              .image {
                cursor: pointer;
                border-radius: 3px;
                height: 130px;
                width: 100px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .detail-container {
    padding: 10px 18px;
    height: fit-content;

    margin-top: 20px;
    margin-bottom: 20px;
    .single-rug-detail {
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;
      flex-direction: column;
      padding: 0px 0px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        overflow: hidden;

        .rug-image {
          padding: 10px 10px;
          object-fit: contain !important;
          height: 550px;
          width: 100%;
        }
      }
      .detail {
        width: 100%;
        padding: 10px 10px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 30px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 30px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;
            flex-direction: column;
            .designImages {
              display: flex;
              gap: 15px;
              flex-direction: row;
              .image {
                cursor: pointer;
                border-radius: 3px;
                height: 100px;
                width: 80px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .detail-container {
    padding: 10px 18px;
    height: fit-content;
    margin-top: 20px;

    margin-bottom: 20px;
    .single-rug-detail {
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;
      flex-direction: column;
      padding: 0px 0px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        overflow: hidden;

        .rug-image {
          padding: 10px 10px;
          object-fit: cover;
          height: 500px;
          // width: 275px;
          width: 100%;
        }
      }
      .detail {
        width: 100%;
        padding: 10px 10px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 30px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 30px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;
            flex-direction: column;
            .designImages {
              display: flex;
              gap: 15px;
              flex-direction: row;
              .image {
                cursor: pointer;
                border-radius: 3px;
                height: 70px;
                width: 60px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 385px) {
  .detail-container {
    padding: 10px 18px;
    height: fit-content;
    margin-top: 20px;

    margin-bottom: 20px;
    .single-rug-detail {
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;
      flex-direction: column;
      padding: 0px 0px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        overflow: hidden;

        .rug-image {
          padding: 10px 10px;
          object-fit: cover;
          height: 450px;
          // width: 275px;
          width: 100%;
        }
      }
      .detail {
        width: 100%;
        padding: 10px 10px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 30px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 30px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;
            flex-direction: column;
            .designImages {
              display: flex;
              gap: 15px;
              flex-direction: row;
              .image {
                cursor: pointer;
                border-radius: 3px;
                height: 70px;
                width: 60px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .detail-container {
    padding: 10px 18px;
    height: fit-content;
    margin-top: 20px;

    margin-bottom: 20px;
    .single-rug-detail {
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;
      flex-direction: column;
      padding: 0px 0px;
      background-color: #fff;
      border-radius: 3px;
      width: fit-content;
      .image {
        overflow: hidden;

        .rug-image {
          padding: 10px 10px;
          object-fit: cover;
          height: 350px;
          width: 250px;
        }
      }
      .detail {
        width: 100%;
        padding: 10px 10px;
        .title {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: rgb(70, 70, 70);
          .rugName {
            font-size: 24px;
            font-weight: 400;
          }
          .addtofav {
            float: right;
            font-weight: 300;
            .fav {
              cursor: pointer;
            }
          }
        }
        .horizontal-divider {
          margin-top: 10px;
          color: rgb(221, 221, 221);
          border: none;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
        .design {
          display: flex;
          gap: 30px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: $primary;
          }
        }
        .colors {
          display: flex;
          gap: 30px;
          padding-top: 10px;
          .type {
            color: rgb(80, 80, 80);
            font-weight: 400;
          }
          .value {
            color: #4f504f;
            font-weight: 400;
          }
          .color-image {
            display: flex;
            gap: 8px;
            flex-direction: column;
            .designImages {
              display: flex;
              gap: 15px;
              flex-direction: column;
              .image {
                cursor: pointer;
                border-radius: 3px;
                height: 100px;
                width: 80px;
              }
              .active {
                border-radius: 3px;
                border: 2px solid white;
                box-shadow: 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }
  }
}
