$primary: #0099cc;
.change-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 50vh;
  .change-password {
    width: 350px;
    height: fit-content;
    padding: 20px 30px;
    background-color: #fff;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    display: flex;
    gap: 20px;
    flex-direction: column;
    h2 {
      color: $primary;
    }
    input {
      font-size: 16px;
      width: 100% !important;
      padding: 15px 10px;
      height: 35px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }
    .changePwdBtn {
      -webkit-appearance: none;
      width: 100%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 16px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }
}

.forget-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 50vh;
  .check-email-container {
    width: 400px;
    background-color: rgb(255, 255, 255);
    height: fit-content;
    padding: 20px 30px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    display: flex;
    gap: 20px;
    flex-direction: column;
    h2 {
      color: $primary;
    }
    label {
      color: rgb(36, 36, 36);
    }
    input {
      font-size: 16px;
      width: 100% !important;
      padding: 15px 10px;
      height: 35px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }
    .confirmEmailBtn {
      -webkit-appearance: none;
      width: 100%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 16px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }
}
