$primary: #0099cc;
.register-container {
  height: 90vh;
  margin-top: 10px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .session {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    height: fit-content;
    margin: auto auto;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
  }
}

.signup-form {
  width: 650px;
  padding: 40px 30px;
  .title {
    font-size: 26px;
    font-weight: 500;
    opacity: 0.85;
  }
  .sub-title {
    font-size: 16px;
    color: $primary;
    font-weight: 400;
  }
  form {
    margin-top: 20px;
    background: #fefefe;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    width: fit-content;
  }
  input {
    font-size: 16px;
    width: 100% !important;
    padding: 20px 5px;
    height: 35px;
    border-radius: 3px;
    border: solid 1px rgba(85, 83, 83, 0.1);
    background: #fff;
    // width: 280px;
    box-sizing: border-box;
    transition: all 0.3s linear;
    color: rgb(54, 54, 54);
    font-weight: 400;
    -webkit-appearance: none;
    &:focus {
      border: solid 1px $primary;
      outline: 0;
      box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
    }
  }
  .registerBtn {
    -webkit-appearance: none;
    width: 50%;
    min-width: 100px;
    border-radius: 4px;
    text-align: center;
    padding: 10px 20px;
    margin-top: 5px;
    background-color: saturate($primary, 30%);
    color: #fff;
    font-size: 18px;
    margin-left: auto;
    font-weight: 400;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
    border: none;
    transition: all 0.3s ease;
    outline: 0;
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
      &:active {
        transform: scale(0.99);
      }
    }
  }
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  .row {
    gap: 10px;
    display: flex;
    .input-box {
      flex: 0.5;
    }
  }
}

// a.discrete {
//   color: rgba($primary, 0.8);
//   font-size: 14px;
//   padding-bottom: 4px;
//   text-decoration: none;
//   margin-left: auto;
//   font-weight: 400;
//   transition: all 0.3s ease;
//   margin-top: 5px;
// }

$displacement: 3px;
@keyframes shake-shake {
  0% {
    transform: translateX(-$displacement);
  }
  20% {
    transform: translateX($displacement);
  }
  40% {
    transform: translateX(-$displacement);
  }
  60% {
    transform: translateX($displacement);
  }
  80% {
    transform: translateX(-$displacement);
  }
  100% {
    transform: translateX(0px);
  }
}
#no-account {
  // margin-top: 12px;
  font-size: 16px;
  text-decoration: none;
  span {
    font-weight: 400;
    text-decoration: none !important;
    color: $primary;
  }
}

.left {
  width: 340px;
  height: auto;
  position: relative;
  background-image: url("../../assets/img/login.jpeg");
  background-size: cover;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

@media screen and (max-width: 1060px) {
  $primary: #1396d6;
  .register-container {
    height: fit-content;
    margin-top: 10px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .session {
      display: flex;
      flex-direction: row-reverse;
      width: fit-content;
      height: fit-content;
      margin: auto auto;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    }
  }

  .signup-form {
    width: 650px;
    padding: 40px 30px;
    .title {
      font-size: 26px;
      font-weight: 500;
      opacity: 0.85;
    }
    .sub-title {
      font-size: 16px;
      color: $primary;
      font-weight: 400;
    }
    form {
      margin-top: 20px;
      background: #fefefe;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
      width: fit-content;
    }
    input {
      font-size: 16px;
      width: 100% !important;
      padding: 20px 5px;
      height: 35px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }
    .registerBtn {
      -webkit-appearance: none;
      width: 50%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 18px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }

  .user-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .row {
      gap: 10px;
      display: flex;
      .input-box {
        flex: 0.5;
      }
    }
  }

  $displacement: 3px;
  @keyframes shake-shake {
    0% {
      transform: translateX(-$displacement);
    }
    20% {
      transform: translateX($displacement);
    }
    40% {
      transform: translateX(-$displacement);
    }
    60% {
      transform: translateX($displacement);
    }
    80% {
      transform: translateX(-$displacement);
    }
    100% {
      transform: translateX(0px);
    }
  }
  #no-account {
    margin-top: 12px;
    font-size: 16px;
    text-decoration: none;
    span {
      font-weight: 400;
      text-decoration: none !important;
      color: $primary;
    }
  }

  .left {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  $primary: #1396d6;
  .register-container {
    height: fit-content;
    margin-top: 20px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .session {
      display: flex;
      flex-direction: row-reverse;
      width: fit-content;
      height: fit-content;
      margin: auto auto;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    }
  }

  .signup-form {
    width: 550px;
    padding: 40px 30px;
    .title {
      font-size: 24px;
      font-weight: 500;
      opacity: 0.85;
    }
    .sub-title {
      font-size: 15px;
      color: $primary;
      font-weight: 400;
    }
    form {
      margin-top: 20px;
      background: #fefefe;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
      width: fit-content;
    }
    input {
      font-size: 16px;
      width: 100% !important;
      padding: 20px 5px;
      height: 35px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }
    .registerBtn {
      -webkit-appearance: none;
      width: 50%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 18px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }

  .user-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .row {
      gap: 10px;
      display: flex;
      .input-box {
        flex: 0.5;
      }
    }
  }

  $displacement: 3px;
  @keyframes shake-shake {
    0% {
      transform: translateX(-$displacement);
    }
    20% {
      transform: translateX($displacement);
    }
    40% {
      transform: translateX(-$displacement);
    }
    60% {
      transform: translateX($displacement);
    }
    80% {
      transform: translateX(-$displacement);
    }
    100% {
      transform: translateX(0px);
    }
  }
  #no-account {
    margin-top: 12px;
    font-size: 16px;
    text-decoration: none;
    span {
      font-weight: 400;
      text-decoration: none !important;
      color: $primary;
    }
  }

  .left {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  $primary: #1396d6;
  .register-container {
    height: fit-content;
    margin-top: 20px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .session {
      display: flex;
      flex-direction: row-reverse;
      width: fit-content;
      height: fit-content;
      margin: auto auto;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    }
  }

  .signup-form {
    padding: 40px 30px;
    width: fit-content;
    .title {
      font-size: 24px;
      font-weight: 500;
      opacity: 0.85;
    }
    .sub-title {
      font-size: 15px;
      color: $primary;
      font-weight: 400;
    }
    form {
      margin-top: 20px;
      background: #fefefe;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
      width: fit-content;
    }
    input {
      font-size: 16px;
      width: 100% !important;
      padding: 20px 5px;
      height: 35px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }
    .registerBtn {
      -webkit-appearance: none;
      width: 100%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 18px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }

  .user-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .row {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }
  }

  $displacement: 3px;
  @keyframes shake-shake {
    0% {
      transform: translateX(-$displacement);
    }
    20% {
      transform: translateX($displacement);
    }
    40% {
      transform: translateX(-$displacement);
    }
    60% {
      transform: translateX($displacement);
    }
    80% {
      transform: translateX(-$displacement);
    }
    100% {
      transform: translateX(0px);
    }
  }
  #no-account {
    margin-top: 12px;
    font-size: 16px;
    text-decoration: none;
    span {
      font-weight: 400;
      text-decoration: none !important;
      color: $primary;
    }
  }

  .left {
    display: none;
  }
}
@media screen and (max-width: 440px) {
  $primary: #1396d6;
  .register-container {
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .session {
      display: flex;
      flex-direction: row-reverse;
      width: fit-content;
      height: fit-content;
      margin: auto auto;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    }
  }

  .signup-form {
    padding: 30px 20px;
    width: 100%;
    .title {
      font-size: 24px;
      font-weight: 500;
      opacity: 0.85;
    }
    .sub-title {
      font-size: 14px;
      color: $primary;
      font-weight: 400;
    }
    form {
      margin-top: 20px;
      background: #fefefe;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
      width: fit-content;
    }
    input {
      font-size: 12px;
      width: 100% !important;
      padding: 20px 5px;
      height: 30px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }
    .registerBtn {
      -webkit-appearance: none;
      width: 100%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 18px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }

  .user-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .row {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }
  }

  $displacement: 3px;
  @keyframes shake-shake {
    0% {
      transform: translateX(-$displacement);
    }
    20% {
      transform: translateX($displacement);
    }
    40% {
      transform: translateX(-$displacement);
    }
    60% {
      transform: translateX($displacement);
    }
    80% {
      transform: translateX(-$displacement);
    }
    100% {
      transform: translateX(0px);
    }
  }
  #no-account {
    margin-top: 12px;
    font-size: 16px;
    text-decoration: none;
    span {
      font-weight: 400;
      text-decoration: none !important;
      color: $primary;
    }
  }

  .left {
    display: none;
  }
}

@media screen and (max-width: 376px) {
  $primary: #1396d6;
  .register-container {
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .session {
      display: flex;
      flex-direction: row-reverse;
      width: fit-content;
      height: fit-content;
      margin: auto auto;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    }
  }

  .signup-form {
    padding: 30px 20px;
    width: 100%;
    .title {
      font-size: 24px;
      font-weight: 500;
      opacity: 0.85;
    }
    .sub-title {
      font-size: 14px;
      color: $primary;
      font-weight: 400;
    }
    form {
      margin-top: 20px;
      background: #fefefe;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
      width: fit-content;
    }
    input {
      font-size: 12px;
      width: 100% !important;
      padding: 20px 5px;
      height: 30px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }
    .registerBtn {
      -webkit-appearance: none;
      width: 100%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 18px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }

  .user-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .row {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }
  }

  $displacement: 3px;
  @keyframes shake-shake {
    0% {
      transform: translateX(-$displacement);
    }
    20% {
      transform: translateX($displacement);
    }
    40% {
      transform: translateX(-$displacement);
    }
    60% {
      transform: translateX($displacement);
    }
    80% {
      transform: translateX(-$displacement);
    }
    100% {
      transform: translateX(0px);
    }
  }
  #no-account {
    margin-top: 12px;
    font-size: 16px;
    text-decoration: none;
    span {
      font-weight: 400;
      text-decoration: none !important;
      color: $primary;
    }
  }

  .left {
    display: none;
  }
}
