.cat-container {
  margin-bottom: 40px;

  .cat-body {
    .cat-detail {
      padding: 60px 120px;
      display: grid;
      gap: 40px;
      grid-template-columns: repeat(4, 1fr);

      .card-content {
        // padding: 0px 80px;
        height: 200px;
        max-width: 290px;
        position: relative;
        border-radius: 10px;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
          border-radius: 10px;
        }
        .overlay {
          width: 100%;
          height: 100%;
          //   background-color: rgba(0, 0, 0, 0.3);
          background: linear-gradient(
            10deg,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
          );

          display: flex;
          align-items: center;
          justify-content: center;
          align-items: flex-end;
          border-radius: 10px;

          //   .catName {
          //     width: 100%;
          //     color: black;
          //     background-color: rgba($color: #fff, $alpha: 0.6);
          //     text-align: center;
          //     padding: 10px 0;
          //   }
          .catName {
            width: 100%;
            color: rgb(255, 255, 255);
            // background-color: rgba($color: #fff, $alpha: 0.6);
            text-align: bottom;

            h3 {
              font-weight: 400;
              padding-left: 10px;
              padding-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1700px) {
  .cat-container {
    .cat-body {
      .cat-detail {
        padding: 60px 120px;
        display: grid;
        gap: 40px;
        grid-template-columns: repeat(5, 1fr);

        .card-content {
          // padding: 0px 80px;
          height: 230px;
          max-width: 290px;
          position: relative;
          border-radius: 10px;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: 10px;
          }
          .overlay {
            width: 100%;
            height: 100%;
            //   background-color: rgba(0, 0, 0, 0.3);
            background: linear-gradient(
              10deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            );

            display: flex;
            align-items: center;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;

            //   .catName {
            //     width: 100%;
            //     color: black;
            //     background-color: rgba($color: #fff, $alpha: 0.6);
            //     text-align: center;
            //     padding: 10px 0;
            //   }
            .catName {
              width: 100%;
              color: rgb(255, 255, 255);
              // background-color: rgba($color: #fff, $alpha: 0.6);
              text-align: bottom;

              h3 {
                font-weight: 400;
                padding-left: 10px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1250px) {
  .cat-container {
    .cat-body {
      .cat-detail {
        padding: 60px 100px;
        display: grid;
        gap: 40px;
        grid-template-columns: repeat(4, 1fr);

        .card-content {
          // padding: 0px 80px;
          height: 210px;
          max-width: 280px;
          position: relative;
          border-radius: 10px;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: 10px;
          }
          .overlay {
            width: 100%;
            height: 100%;
            //   background-color: rgba(0, 0, 0, 0.3);
            background: linear-gradient(
              10deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            );

            display: flex;
            align-items: center;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;

            .catName {
              width: 100%;
              color: rgb(255, 255, 255);
              // background-color: rgba($color: #fff, $alpha: 0.6);
              text-align: bottom;

              h3 {
                font-weight: 400;
                padding-left: 10px;
                font-size: 16px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1150px) {
  .cat-container {
    .cat-body {
      .cat-detail {
        padding: 60px 100px;
        display: grid;
        gap: 40px;
        grid-template-columns: repeat(3, 1fr);

        .card-content {
          // padding: 0px 80px;
          height: 210px;
          max-width: 280px;
          position: relative;
          border-radius: 10px;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: 10px;
          }
          .overlay {
            width: 100%;
            height: 100%;
            //   background-color: rgba(0, 0, 0, 0.3);
            background: linear-gradient(
              10deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            );

            display: flex;
            align-items: center;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;

            .catName {
              width: 100%;
              color: rgb(255, 255, 255);
              // background-color: rgba($color: #fff, $alpha: 0.6);
              text-align: bottom;

              h3 {
                font-weight: 400;
                padding-left: 10px;
                font-size: 16px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .cat-container {
    .cat-body {
      .cat-detail {
        padding: 60px 80px;
        display: grid;
        gap: 25px;
        grid-template-columns: repeat(3, 1fr);

        .card-content {
          // padding: 0px 80px;
          height: 200px;
          max-width: 250px;
          position: relative;
          border-radius: 10px;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: 10px;
          }
          .overlay {
            width: 100%;
            height: 100%;
            //   background-color: rgba(0, 0, 0, 0.3);
            background: linear-gradient(
              10deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            );

            display: flex;
            align-items: center;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;

            .catName {
              width: 100%;
              color: rgb(255, 255, 255);
              // background-color: rgba($color: #fff, $alpha: 0.6);
              text-align: bottom;

              h3 {
                font-weight: 400;
                padding-left: 10px;
                font-size: 15px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .cat-container {
    .cat-body {
      .cat-detail {
        padding: 60px 60px;
        display: grid;
        gap: 25px;
        grid-template-columns: repeat(2, 1fr);

        .card-content {
          // padding: 0px 80px;
          height: 200px;
          max-width: 250px;
          position: relative;
          border-radius: 10px;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: 10px;
          }
          .overlay {
            width: 100%;
            height: 100%;
            //   background-color: rgba(0, 0, 0, 0.3);
            background: linear-gradient(
              10deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            );

            display: flex;
            align-items: center;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;

            .catName {
              width: 100%;
              color: rgb(255, 255, 255);
              // background-color: rgba($color: #fff, $alpha: 0.6);
              text-align: bottom;

              h3 {
                font-weight: 400;
                padding-left: 10px;
                font-size: 15px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .cat-container {
    .cat-body {
      .cat-detail {
        padding: 60px 60px;
        display: grid;
        gap: 25px;
        grid-template-columns: repeat(2, 1fr);

        .card-content {
          // padding: 0px 80px;
          height: 180px;
          max-width: 230px;
          position: relative;
          border-radius: 10px;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: 10px;
          }
          .overlay {
            width: 100%;
            height: 100%;
            //   background-color: rgba(0, 0, 0, 0.3);
            background: linear-gradient(
              10deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            );

            display: flex;
            align-items: center;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;

            .catName {
              width: 100%;
              color: rgb(255, 255, 255);
              // background-color: rgba($color: #fff, $alpha: 0.6);
              text-align: bottom;

              h3 {
                font-weight: 400;
                padding-left: 10px;
                font-size: 14px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .cat-container {
    .cat-body {
      .cat-detail {
        padding: 60px 40px;
        display: grid;
        gap: 25px;
        grid-template-columns: repeat(2, 1fr);

        .card-content {
          // padding: 0px 80px;
          height: 150px;
          max-width: 200px;
          position: relative;
          border-radius: 10px;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: 10px;
          }
          .overlay {
            width: 100%;
            height: 100%;
            //   background-color: rgba(0, 0, 0, 0.3);
            background: linear-gradient(
              10deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            );

            display: flex;
            align-items: center;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;

            .catName {
              width: 100%;
              color: rgb(255, 255, 255);
              // background-color: rgba($color: #fff, $alpha: 0.6);
              text-align: bottom;

              h3 {
                font-weight: 400;
                padding-left: 10px;
                font-size: 13px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 350px) {
  .cat-container {
    .cat-body {
      .cat-detail {
        padding: 60px 50px;
        display: grid;
        gap: 25px;
        grid-template-columns: repeat(1, 1fr);

        .card-content {
          // padding: 0px 80px;
          height: 160px;
          max-width: 230px;
          position: relative;
          border-radius: 10px;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: 10px;
          }
          .overlay {
            width: 100%;
            height: 100%;
            //   background-color: rgba(0, 0, 0, 0.3);
            background: linear-gradient(
              10deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            );

            display: flex;
            align-items: center;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;

            .catName {
              width: 100%;
              color: rgb(255, 255, 255);
              // background-color: rgba($color: #fff, $alpha: 0.6);
              text-align: bottom;

              h3 {
                font-weight: 400;
                padding-left: 10px;
                font-size: 13px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}
