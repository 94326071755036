$primary: #0099cc;
.fav-container {
  .no-fav {
    padding: 0px 160px;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    h2 {
      color: rgb(63, 63, 63);
    }
    button {
      padding: 10px 20px;
      border-radius: 20px;
      font-size: 18px;
      border: none;
      background-color: $primary;
      color: white;
      cursor: pointer;
    }
  }
  .fav-body {
    padding: 0px 160px;
    .no-search-result {
      height: 30vh;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        color: $primary;
      }
    }
    .rug-details {
      display: grid;
      gap: 40px;
      margin-top: 30px;
      // flex-direction: row;
      // column-count: 4;
      // flex-wrap: wrap;
      width: 100%;
      grid-template-columns: repeat(4, 1fr);
      .card {
        background-color: rgb(255, 255, 255);
        border-radius: 3px;
        max-width: 250px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        height: 420px;
        width: 100%;
        img {
          padding: 10px 10px 2px 10px;
          border-radius: 5px;
          height: 90%;
          object-fit: cover;

          width: 100%;
        }
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      a {
        text-decoration: none;
        color: rgb(48, 47, 47);
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .fav-container {
    .fav-body {
      padding: 0px 100px;
      .rug-details {
        display: grid;
        gap: 40px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 250px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 400px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .fav-container {
    .fav-body {
      padding: 0px 140px;
      .rug-details {
        display: grid;
        gap: 40px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
        grid-template-columns: repeat(3, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 250px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 400px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .fav-container {
    .fav-body {
      padding: 0px 60px;
      .rug-details {
        display: grid;
        gap: 50px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
        grid-template-columns: repeat(3, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 250px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 380px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}

@media screen and (max-width: 940px) {
  .fav-container {
    .fav-body {
      padding: 0px 80px;
      .rug-details {
        display: grid;
        gap: 40px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
        grid-template-columns: repeat(2, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 230px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 360px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}
// ipad screen
@media screen and (max-width: 768px) {
  .fav-container {
    .fav-body {
      padding: 0px 120px;
      .rug-details {
        display: grid;
        gap: 40px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
        grid-template-columns: repeat(2, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 250px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 360px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .fav-container {
    .fav-body {
      padding: 0px 70px;
      .rug-details {
        display: grid;
        gap: 40px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-template-columns: repeat(2, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 250px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 360px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .fav-container {
    .fav-body {
      padding: 0px 40px;
      .rug-details {
        display: grid;
        gap: 20px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
        grid-template-columns: repeat(2, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 220px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 360px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .fav-container {
    .fav-body {
      padding: 0px 90px;
      .rug-details {
        display: grid;
        gap: 40px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
        grid-template-columns: repeat(1, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 250px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 360px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .fav-container {
    .fav-body {
      padding: 0px 70px;
      .rug-details {
        display: grid;
        gap: 40px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-template-columns: repeat(1, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 250px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 360px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .fav-container {
    .fav-body {
      padding: 0px 50px;
      .rug-details {
        display: grid;
        gap: 40px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
        grid-template-columns: repeat(1, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 250px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 360px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .fav-container {
    .fav-body {
      padding: 0px 45px;
      .rug-details {
        display: grid;
        gap: 40px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
        grid-template-columns: repeat(1, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 250px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 360px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}

// galaxy fold
@media screen and (max-width: 280px) {
  .fav-container {
    .fav-body {
      padding: 0px 35px;
      .rug-details {
        display: grid;
        gap: 40px;
        margin-top: 30px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
        grid-template-columns: repeat(1, 1fr);
        .card {
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 210px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 330px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        a {
          text-decoration: none;
          color: rgb(48, 47, 47);
        }
      }
    }
  }
}
