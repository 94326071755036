$primary: #0099cc;

.home-container {
  .top-section {
    height: 65vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    .swiper-button-prev,
    .swiper-button-next {
      color: #fff;
      padding: 0px 30px;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 30px;
    }

    .swiper-pagination-bullet {
      color: rgb(255, 255, 255);
      background: rgb(255, 255, 255);
    }

    .top-container {
      height: 65vh;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: linear-gradient(
        10deg,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0)
      );
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }
      .heading {
        width: 50%;
        color: rgb(255, 255, 255);
        text-align: left;
        padding: 10px 110px;
        .top-title {
          color: #fff;
          padding-bottom: 20px;
          font-size: 42px;
        }

        .top-subtitle {
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          text-align: justify;
          line-height: 1.2;
        }
        .viewAll {
          cursor: pointer;

          margin-top: 20px;
          padding: 8px 30px;
          background-color: $primary;
          font-weight: 500;
          border: none;
          font-size: 16px;
          color: #fff;
          border-radius: 30px;
        }
      }
    }

    .top-center {
      .welcome-title {
        background-color: black;
        z-index: 999;
        font-size: 30px;
      }
    }
    .text-container {
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  .category-section {
    position: relative;
    padding: 20px 0px;
  }
  .rugs-section {
    padding: 20px 80px;
    margin-bottom: 40px;
    position: relative;
    #nextRug,
    #prevRug {
      position: absolute;
      top: 50%;
      transform: translateY(50%);
      z-index: 5;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    #prevRug {
      left: 20px;
    }
    #nextRug {
      right: 20px;
    }
    .swiper-slide {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .home-rug-details {
      .home-card {
        margin-bottom: 10px;
        cursor: pointer;
        background-color: rgb(255, 255, 255);
        border-radius: 3px;
        max-width: 295px;

        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        height: 450px;
        width: 100%;
        display: flex;
        gap: 10px;
        flex-direction: column;
        img {
          padding: 10px 10px 2px 10px;
          border-radius: 5px;
          height: 92%;
          object-fit: contain;
          width: 100%;
        }
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.cat-detail {
  // gap: 500px;
  padding: 0px 80px;
  position: relative;
  #next,
  #prev {
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    z-index: 5;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  #prev {
    left: 25px;
  }
  #next {
    right: 25px;
  }

  .swiper-content {
    // padding: 0px 80px;
    height: 250px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
    .catName {
      width: 100%;
      color: black;
      background-color: rgba($color: #fff, $alpha: 0.6);
      text-align: center;
      padding: 10px 0;
    }
  }
}

.view-more {
  display: flex;
  justify-content: center;
  .btn-view-more {
    padding: 6px 15px;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    background-color: $primary;
    color: #fff;
  }
}

@media screen and (max-width: 1366px) {
  .home-container {
    .top-section {
      height: 65vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      .swiper-button-prev,
      .swiper-button-next {
        color: #fff;
        padding: 0px 30px;
      }
      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-size: 30px;
      }

      .swiper-pagination-bullet {
        color: rgb(255, 255, 255);
        background: rgb(255, 255, 255);
      }

      .top-container {
        height: 65vh;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .heading {
          width: 50%;
          color: rgb(255, 255, 255);
          text-align: left;
          padding: 10px 110px;
          .top-title {
            color: #fff;
            padding-bottom: 20px;
            font-size: 42px;
          }

          .top-subtitle {
            font-weight: 400;
            font-size: 18px;
            color: #ffffff;
            line-height: 1.2;
          }
          .viewAll {
            cursor: pointer;

            margin-top: 20px;
            padding: 8px 30px;
            background-color: $primary;
            font-weight: 500;
            border: none;
            font-size: 16px;
            color: #fff;
            border-radius: 30px;
          }
        }
      }

      .top-center {
        .welcome-title {
          background-color: black;
          z-index: 999;
          font-size: 30px;
        }
      }
      .text-container {
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    .rugs-section {
      padding: 20px 80px;
      position: relative;
      #nextRug,
      #prevRug {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        z-index: 5;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      #prevRug {
        left: 20px;
      }
      #nextRug {
        right: 20px;
      }

      .home-rug-details {
        .home-card {
          margin-bottom: 10px;
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 260px;

          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 395px;
          width: 100%;
          display: flex;
          gap: 5px;
          flex-direction: column;
          img {
            padding: 8px 10px 2px 10px;
            border-radius: 5px;
            height: 92%;
            // height: 100%;

            object-fit: contain;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .cat-detail {
    // gap: 500px;
    padding: 0px 80px;
    position: relative;
    #next,
    #prev {
      position: absolute;
      top: 50%;
      transform: translateY(-72%);
      z-index: 5;
      background-color: transparent;
      border: none;
    }
    #prev {
      left: 20px;
    }
    #next {
      right: 20px;
    }

    .swiper-content {
      // padding: 0px 80px;
      height: 250px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }
      .catName {
        width: 100%;
        color: black;
        background-color: rgba($color: #fff, $alpha: 0.6);
        text-align: center;
        padding: 10px 0;
      }
    }
  }

  .view-more {
    display: flex;
    justify-content: center;
    .btn-view-more {
      padding: 6px 15px;
      cursor: pointer;
      border: none;
      border-radius: 3px;
      background-color: $primary;
      color: #fff;
    }
  }
}
@media screen and (max-width: 1240px) {
  .home-container {
    .top-section {
      height: 65vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      .swiper-button-prev,
      .swiper-button-next {
        color: #fff;
        padding: 0px 30px;
      }
      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-size: 30px;
      }

      .swiper-pagination-bullet {
        color: rgb(255, 255, 255);
        background: rgb(255, 255, 255);
      }

      .top-container {
        height: 65vh;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .heading {
          width: 50%;
          color: rgb(255, 255, 255);
          text-align: left;
          padding: 10px 110px;
          .top-title {
            color: #fff;
            padding-bottom: 20px;
            font-size: 36px;
          }

          .top-subtitle {
            font-weight: 400;
            font-size: 17px;
            color: #ffffff;
            line-height: 1.2;
          }
          .viewAll {
            cursor: pointer;

            margin-top: 20px;
            padding: 8px 30px;
            background-color: $primary;
            font-weight: 500;
            border: none;
            font-size: 15px;
            color: #fff;
            border-radius: 30px;
          }
        }
      }

      .top-center {
        .welcome-title {
          background-color: black;
          z-index: 999;
          font-size: 30px;
        }
      }
      .text-container {
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
}
@media screen and (max-width: 965px) {
  .home-container {
    .top-section {
      height: 65vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      .swiper-button-prev,
      .swiper-button-next {
        color: #fff;
        padding: 0px 30px;
      }
      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-size: 25px;
      }

      .swiper-pagination-bullet {
        color: rgb(255, 255, 255);
        background: rgb(255, 255, 255);
      }

      .top-container {
        height: 65vh;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .heading {
          width: 70%;
          color: rgb(255, 255, 255);
          text-align: left;
          padding: 10px 110px;
          .top-title {
            color: #fff;
            padding-bottom: 20px;
            font-size: 32px;
          }

          .top-subtitle {
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
            line-height: 1.2;
          }
          .viewAll {
            cursor: pointer;
            margin-top: 20px;
            padding: 8px 30px;
            background-color: $primary;
            font-weight: 500;
            border: none;
            font-size: 14px;
            color: #fff;
            border-radius: 30px;
          }
        }
      }

      .top-center {
        .welcome-title {
          background-color: black;
          z-index: 999;
          font-size: 30px;
        }
      }
      .text-container {
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    .rugs-section {
      padding: 20px 80px;
      position: relative;
      #nextRug,
      #prevRug {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        z-index: 5;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      #prevRug {
        left: 20px;
      }
      #nextRug {
        right: 20px;
      }

      .home-rug-details {
        .home-card {
          margin-bottom: 10px;
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 245px;

          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 365px;
          width: 100%;
          display: flex;
          gap: 5px;
          flex-direction: column;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 92%;
            // height: 100%;

            object-fit: contain;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 883px) {
  .home-container {
    .top-section {
      height: 65vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      .swiper-button-prev,
      .swiper-button-next {
        color: #fff;
        padding: 0px 30px;
      }
      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-size: 25px;
      }

      .swiper-pagination-bullet {
        color: rgb(255, 255, 255);
        background: rgb(255, 255, 255);
      }

      .top-container {
        height: 65vh;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .heading {
          width: 70%;
          color: rgb(255, 255, 255);
          text-align: left;
          padding: 10px 110px;
          .top-title {
            color: #fff;
            padding-bottom: 20px;
            font-size: 28px;
          }

          .top-subtitle {
            font-weight: 400;
            font-size: 15px;
            color: #ffffff;
            line-height: 1.2;
          }
          .viewAll {
            cursor: pointer;
            margin-top: 20px;
            padding: 8px 30px;
            background-color: $primary;
            font-weight: 500;
            border: none;
            font-size: 14px;
            color: #fff;
            border-radius: 30px;
          }
        }
      }

      .top-center {
        .welcome-title {
          background-color: black;
          z-index: 999;
          font-size: 30px;
        }
      }
      .text-container {
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    .rugs-section {
      padding: 20px 80px;
      position: relative;
      #nextRug,
      #prevRug {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        z-index: 5;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      #prevRug {
        left: 20px;
      }
      #nextRug {
        right: 20px;
      }

      .home-rug-details {
        .home-card {
          margin-bottom: 10px;
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 290px;

          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 440px;
          width: 100%;
          display: flex;
          gap: 5px;
          flex-direction: column;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 92%;
            // height: 100%;

            object-fit: contain;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            font-size: 16px;
            justify-content: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 756px) {
  .home-container {
    .top-section {
      height: 50vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      .swiper-button-prev,
      .swiper-button-next {
        color: #fff;
        padding: 0px 20px;
      }
      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-size: 20px;
      }

      .swiper-pagination-bullet {
        color: rgb(255, 255, 255);
        background: rgb(255, 255, 255);
      }

      .top-container {
        height: 50vh;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .heading {
          width: 70%;
          color: rgb(255, 255, 255);
          text-align: left;
          padding: 10px 80px;
          .top-title {
            color: #fff;
            padding-bottom: 20px;
            font-size: 26px;
          }

          .top-subtitle {
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 1.2;
          }
          .viewAll {
            cursor: pointer;
            margin-top: 20px;
            padding: 8px 30px;
            background-color: $primary;
            font-weight: 500;
            border: none;
            font-size: 14px;
            color: #fff;
            border-radius: 30px;
          }
        }
      }

      .top-center {
        .welcome-title {
          background-color: black;
          z-index: 999;
          font-size: 30px;
        }
      }
      .text-container {
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    .rugs-section {
      padding: 20px 80px;
      position: relative;
      #nextRug,
      #prevRug {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        z-index: 5;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      #prevRug {
        left: 20px;
      }
      #nextRug {
        right: 20px;
      }

      .home-rug-details {
        .home-card {
          margin-bottom: 10px;
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 260px;

          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 400px;
          width: 100%;
          display: flex;
          gap: 5px;
          flex-direction: column;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 92%;
            // height: 100%;

            object-fit: contain;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            font-size: 16px;
            justify-content: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 695px) {
  .home-container {
    .top-section {
      height: 50vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      .swiper-button-prev,
      .swiper-button-next {
        color: #fff;
        padding: 0px 20px;
      }
      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-size: 20px;
      }

      .swiper-pagination-bullet {
        color: rgb(255, 255, 255);
        background: rgb(255, 255, 255);
      }

      .top-container {
        height: 50vh;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .heading {
          width: 70%;
          color: rgb(255, 255, 255);
          text-align: left;
          padding: 10px 80px;
          .top-title {
            color: #fff;
            padding-bottom: 20px;
            font-size: 26px;
          }

          .top-subtitle {
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 1.2;
          }
          .viewAll {
            cursor: pointer;
            margin-top: 20px;
            padding: 8px 30px;
            background-color: $primary;
            font-weight: 500;
            border: none;
            font-size: 14px;
            color: #fff;
            border-radius: 30px;
          }
        }
      }
    }
    .rugs-section {
      padding: 20px 80px;
      position: relative;
      #nextRug,
      #prevRug {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        z-index: 5;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      #prevRug {
        left: 20px;
      }
      #nextRug {
        right: 20px;
      }

      .home-rug-details {
        .home-card {
          margin-bottom: 10px;
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 240px;

          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 370px;
          width: 100%;
          display: flex;
          gap: 5px;
          flex-direction: column;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 92%;
            // height: 100%;

            object-fit: contain;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            font-size: 16px;
            justify-content: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .home-container {
    .top-section {
      height: 50vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      .swiper-button-prev,
      .swiper-button-next {
        color: #fff;
        padding: 0px 20px;
      }
      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-size: 20px;
      }

      .swiper-pagination-bullet {
        color: rgb(255, 255, 255);
        background: rgb(255, 255, 255);
      }

      .top-container {
        height: 50vh;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .heading {
          width: 70%;
          color: rgb(255, 255, 255);
          text-align: left;
          padding: 10px 60px;
          .top-title {
            color: #fff;
            padding-bottom: 20px;
            font-size: 20px;
          }

          .top-subtitle {
            font-weight: 400;
            font-size: 13px;
            color: #ffffff;
            line-height: 1.2;
          }
          .viewAll {
            cursor: pointer;
            margin-top: 20px;
            padding: 8px 30px;
            background-color: $primary;
            font-weight: 500;
            border: none;
            font-size: 13px;
            color: #fff;
            border-radius: 30px;
          }
        }
      }
    }
    .rugs-section {
      padding: 20px 80px;
      position: relative;
      #nextRug,
      #prevRug {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        z-index: 5;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      #prevRug {
        left: 20px;
      }
      #nextRug {
        right: 20px;
      }

      .home-rug-details {
        .home-card {
          margin-bottom: 10px;
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 290px;

          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 440px;
          width: 100%;
          display: flex;
          gap: 5px;
          flex-direction: column;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 92%;
            // height: 100%;

            object-fit: contain;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            font-size: 16px;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .home-container {
    .top-section {
      height: 45vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      .swiper-button-prev,
      .swiper-button-next {
        color: #fff;
        padding: 0px 10px;
      }
      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-size: 20px;
      }

      .swiper-pagination-bullet {
        color: rgb(255, 255, 255);
        background: rgb(255, 255, 255);
      }

      .top-container {
        height: 45vh;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .heading {
          width: 80%;
          color: rgb(255, 255, 255);
          text-align: left;
          padding: 10px 50px;
          .top-title {
            color: #fff;
            padding-bottom: 20px;
            font-size: 18px;
          }

          .top-subtitle {
            font-weight: 400;
            font-size: 11px;
            color: #ffffff;
            line-height: 1.2;
          }
          .viewAll {
            cursor: pointer;
            margin-top: 20px;
            padding: 8px 25px;
            background-color: $primary;
            font-weight: 500;
            border: none;
            font-size: 10px;
            color: #fff;
            border-radius: 30px;
          }
        }
      }
    }
    .cat-detail {
      // gap: 500px;
      padding: 0px 50px;
      position: relative;
      #next,
      #prev {
        position: absolute;
        top: 50%;
        transform: translateY(-72%);
        z-index: 5;
        background-color: transparent;
        border: none;
      }
      #prev {
        left: 15px;
      }
      #next {
        right: 15px;
      }

      .swiper-content {
        // padding: 0px 80px;
        height: 200px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .catName {
          width: 100%;
          color: black;
          background-color: rgba($color: #fff, $alpha: 0.6);
          text-align: center;
          padding: 10px 0;
        }
      }
    }
    .rugs-section {
      padding: 20px 60px;
      position: relative;
      #nextRug,
      #prevRug {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        z-index: 5;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      #prevRug {
        left: 10px;
      }
      #nextRug {
        right: 10px;
      }

      .home-rug-details {
        .home-card {
          margin-bottom: 10px;
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 260px;

          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 400px;
          width: 100%;
          display: flex;
          gap: 5px;
          flex-direction: column;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 92%;
            // height: 100%;

            object-fit: contain;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            font-size: 16px;
            justify-content: center;
          }
        }
      }
    }

    .view-more {
      display: flex;
      justify-content: center;
      .btn-view-more {
        padding: 6px 15px;
        cursor: pointer;
        border: none;
        border-radius: 3px;
        background-color: $primary;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .home-container {
    .top-section {
      height: 45vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      .swiper-button-prev,
      .swiper-button-next {
        color: #fff;
        padding: 0px 10px;
      }
      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-size: 20px;
      }

      .swiper-pagination-bullet {
        color: rgb(255, 255, 255);
        background: rgb(255, 255, 255);
      }

      .top-container {
        height: 45vh;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .heading {
          width: 80%;
          color: rgb(255, 255, 255);
          text-align: left;
          padding: 10px 50px;
          .top-title {
            color: #fff;
            padding-bottom: 20px;
            font-size: 16px;
          }

          .top-subtitle {
            font-weight: 400;
            font-size: 10px;
            color: #ffffff;
            // line-height: 1.2;
          }
          .viewAll {
            cursor: pointer;
            margin-top: 20px;
            padding: 8px 25px;
            background-color: $primary;
            font-weight: 500;
            border: none;
            font-size: 9px;
            color: #fff;
            border-radius: 30px;
          }
        }
      }
    }
    .cat-detail {
      // gap: 500px;
      padding: 0px 45px;
      position: relative;
      #next,
      #prev {
        position: absolute;
        top: 50%;
        transform: translateY(-72%);
        z-index: 5;
        background-color: transparent;
        border: none;
      }
      #prev {
        left: 10px;
      }
      #next {
        right: 10px;
      }

      .swiper-content {
        // padding: 0px 80px;
        height: 200px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .catName {
          width: 100%;
          color: black;
          background-color: rgba($color: #fff, $alpha: 0.6);
          text-align: center;
          padding: 10px 0;
        }
      }
    }
    .rugs-section {
      padding: 20px 40px;
      position: relative;
      #nextRug,
      #prevRug {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        z-index: 5;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      #prevRug {
        left: 8px;
      }
      #nextRug {
        right: 8px;
      }

      .home-rug-details {
        .home-card {
          margin-bottom: 10px;
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 240px;

          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 360px;
          width: 100%;
          display: flex;
          gap: 5px;
          flex-direction: column;
          img {
            padding: 8px 8px 2px 8px;
            border-radius: 5px;
            height: 92%;
            // height: 100%;

            object-fit: contain;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            font-size: 16px;
            justify-content: center;
          }
        }
      }
    }
    .view-more {
      display: flex;
      justify-content: center;
      .btn-view-more {
        padding: 6px 15px;
        cursor: pointer;
        border: none;
        border-radius: 3px;
        background-color: $primary;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .home-container {
    .top-section {
      height: 40vh;
      width: 100%;
      overflow: hidden;
      position: relative;
      .swiper-button-prev,
      .swiper-button-next {
        color: #fff;
        padding: 0px 10px;
      }
      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-size: 20px;
      }

      .swiper-pagination-bullet {
        color: rgb(255, 255, 255);
        background: rgb(255, 255, 255);
      }

      .top-container {
        height: 40vh;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .heading {
          width: 80%;
          color: rgb(255, 255, 255);
          text-align: left;
          padding: 10px 50px;
          .top-title {
            color: #fff;
            padding-bottom: 20px;
            font-size: 16px;
          }

          .top-subtitle {
            font-weight: 400;
            font-size: 10px;
            color: #ffffff;
            // line-height: 1.2;
          }
          .viewAll {
            cursor: pointer;
            margin-top: 20px;
            padding: 8px 25px;
            background-color: $primary;
            font-weight: 500;
            border: none;
            font-size: 9px;
            color: #fff;
            border-radius: 30px;
          }
        }
      }
    }
    .rugs-section {
      padding: 20px 40px;
      position: relative;
      #nextRug,
      #prevRug {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        z-index: 5;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      #prevRug {
        left: 8px;
      }
      #nextRug {
        right: 8px;
      }

      .home-rug-details {
        .home-card {
          margin-bottom: 10px;
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          max-width: 220px;

          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 340px;
          width: 100%;
          display: flex;
          gap: 5px;
          flex-direction: column;
          img {
            padding: 8px 8px 2px 8px;
            border-radius: 5px;
            height: 92%;
            // height: 100%;

            object-fit: contain;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: center;
          }
        }
      }
    }
    .cat-detail {
      // gap: 500px;
      padding: 0px 45px;
      position: relative;
      #next,
      #prev {
        position: absolute;
        top: 50%;
        transform: translateY(-72%);
        z-index: 5;
        background-color: transparent;
        border: none;
      }
      #prev {
        left: 10px;
      }
      #next {
        right: 10px;
      }

      .swiper-content {
        // padding: 0px 80px;
        height: 200px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .catName {
          width: 100%;
          color: black;
          background-color: rgba($color: #fff, $alpha: 0.6);
          text-align: center;
          padding: 10px 0;
        }
      }
    }

    .view-more {
      display: flex;
      justify-content: center;
      .btn-view-more {
        padding: 6px 15px;
        cursor: pointer;
        border: none;
        border-radius: 3px;
        background-color: $primary;
        color: #fff;
      }
    }
  }
}
