$primary: #0099cc;
.contact-container {
  overflow: hidden;
  height: auto;
  margin-bottom: 40px;

  .contact-body {
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 160px;

    .contact-detail {
      display: flex;
      height: 620px;
      border-radius: 5px;
      // justify-content: center;
      // gap: 20px;
      border-radius: 3px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background-color: #fff;
      .imagebox {
        padding: 10px;
        background-color: white;
        flex: 0.5;

        object-fit: cover;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .textbox {
        flex: 0.5;
        margin-top: 20px;

        .contact {
          padding: 20px 10px;
          form {
            background: #fefefe;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 20px;
            gap: 40px;
            width: fit-content;
          }
          input {
            font-size: 16px;
            width: 100%;
            padding: 15px 10px;

            height: 35px;
            border-radius: 3px;
            border: solid 1px rgba(85, 83, 83, 0.1);
            background: #fff;
            // width: 280px;
            box-sizing: border-box;
            transition: all 0.3s linear;
            color: rgb(54, 54, 54);
            font-weight: 400;
            -webkit-appearance: none;
            &:focus {
              border: solid 1px $primary;
              outline: 0;
              box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
            }
          }
          .input-field {
            margin-bottom: 15px;
          }

          textarea {
            font-size: 16px;
            width: 100%;
            padding: 10px 10px;

            margin-bottom: 10px;
            columns: 5;
            resize: none;

            height: 120px;
            border-radius: 3px;
            border: solid 1px rgba(85, 83, 83, 0.1);
            background: #fff;
            // width: 280px;
            box-sizing: border-box;
            transition: all 0.3s linear;
            color: rgb(54, 54, 54);
            font-weight: 400;
            -webkit-appearance: none;
            &:focus {
              border: solid 1px $primary;
              outline: 0;
              box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
            }
          }
          label {
            font-size: 16px;
            display: block;
            color: rgb(59, 59, 59);
            opacity: 0.8;
            font-weight: 400;
          }
          button {
            -webkit-appearance: none;
            width: 100%;
            min-width: 100px;
            border-radius: 4px;
            text-align: center;
            padding: 10px 20px;
            margin-top: 5px;
            background-color: saturate($primary, 30%);
            color: #fff;
            font-size: 18px;
            margin-left: auto;
            font-weight: 400;
            box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
            border: none;
            transition: all 0.3s ease;
            outline: 0;
            &:hover {
              transform: translateY(-3px);
              box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
              &:active {
                transform: scale(0.99);
              }
            }
          }
        }
      }
      // .textbox {
      //   flex: 0.4;
      //   text-align: left;
      //   padding-top: 10px;
      //   color: rgb(29, 28, 28);
      //   h3 {
      //     font-weight: 600;
      //   }
      //   p {
      //     margin-top: 5px;
      //   }
      //   .address {
      //     margin-bottom: 20px;
      //   }
      //   .contact {
      //     margin-bottom: 20px;
      //   }
      // }
      // .social-media {
      //   display: flex;
      //   justify-content: start;
      //   flex-direction: row;
      //   gap: 6px;
      // }
    }

    .contact-heading {
      margin: 20px 0px;
      .title {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        font-weight: 600;
      }
      .subtitle {
        width: 100%;
        color: $primary;
        justify-content: center;
        align-items: center;
        display: flex;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .contact-container {
    overflow: hidden;
    height: auto;
    margin-bottom: 40px;

    .contact-body {
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: center;
      align-items: flex-start;
      padding: 10px 160px;

      .contact-detail {
        display: flex;
        height: fit-content;
        width: 100%;
        border-radius: 5px;
        // justify-content: center;
        // gap: 20px;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: #fff;
        .imagebox {
          display: none;
        }

        .textbox {
          flex: 1;
          // margin-top: 20px;
          padding: 20px;
          // width: 100%;
          .contact {
            padding: 20px 10px;
            form {
              background: #fefefe;
              height: fit-content;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding-bottom: 20px;
              gap: 40px;
              width: fit-content;
            }
            .input-field {
              margin-bottom: 15px;
            }
            input {
              font-size: 16px;
              width: 100%;
              padding: 15px 10px;

              height: 35px;
              border-radius: 3px;
              border: solid 1px rgba(85, 83, 83, 0.1);
              background: #fff;
              // width: 280px;
              box-sizing: border-box;
              transition: all 0.3s linear;
              color: rgb(54, 54, 54);
              font-weight: 400;
              -webkit-appearance: none;
              &:focus {
                border: solid 1px $primary;
                outline: 0;
                box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
              }
            }
            textarea {
              font-size: 16px;
              width: 100%;
              padding: 10px 10px;

              margin-bottom: 10px;
              columns: 5;
              resize: none;

              height: 120px;
              border-radius: 3px;
              border: solid 1px rgba(85, 83, 83, 0.1);
              background: #fff;
              // width: 280px;
              box-sizing: border-box;
              transition: all 0.3s linear;
              color: rgb(54, 54, 54);
              font-weight: 400;
              -webkit-appearance: none;
              &:focus {
                border: solid 1px $primary;
                outline: 0;
                box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
              }
            }
            label {
              font-size: 16px;
              display: block;
              color: rgb(59, 59, 59);
              opacity: 0.8;
              font-weight: 400;
            }
            button {
              -webkit-appearance: none;
              width: 100%;
              min-width: 100px;
              border-radius: 4px;
              text-align: center;
              padding: 10px 20px;
              margin-top: 5px;
              background-color: saturate($primary, 30%);
              color: #fff;
              font-size: 18px;
              margin-left: auto;
              font-weight: 400;
              box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
              border: none;
              transition: all 0.3s ease;
              outline: 0;
              &:hover {
                transform: translateY(-3px);
                box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
                &:active {
                  transform: scale(0.99);
                }
              }
            }
          }
        }
      }

      .contact-heading {
        margin: 20px 0px;
        .title {
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          font-weight: 600;
        }
        .subtitle {
          width: 100%;
          color: $primary;
          justify-content: center;
          align-items: center;
          display: flex;
          font-weight: 500;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .contact-container {
    overflow: hidden;
    height: auto;
    // margin-bottom: 40px;

    .contact-body {
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: center;
      align-items: flex-start;
      padding: 10px 100px;

      .contact-detail {
        display: flex;
        height: fit-content;
        width: 100%;
        border-radius: 5px;
        // justify-content: center;
        // gap: 20px;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: #fff;
        .imagebox {
          display: none;
        }
        .textbox {
          flex: 1;
          margin-top: 0px;
          padding: 20px;
          // width: 100%;
          .contact {
            padding: 20px 10px;
            form {
              background: #fefefe;
              height: fit-content;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding-bottom: 20px;
              gap: 40px;
              width: fit-content;
            }
            .input-field {
              margin-bottom: 15px;
            }
            input {
              font-size: 16px;
              width: 100%;
              padding: 15px 10px;

              height: 35px;
              border-radius: 3px;
              border: solid 1px rgba(85, 83, 83, 0.1);
              background: #fff;
              // width: 280px;
              box-sizing: border-box;
              transition: all 0.3s linear;
              color: rgb(54, 54, 54);
              font-weight: 400;
              -webkit-appearance: none;
              &:focus {
                border: solid 1px $primary;
                outline: 0;
                box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
              }
            }
            textarea {
              font-size: 16px;
              width: 100%;
              padding: 10px 10px;

              margin-bottom: 10px;
              columns: 5;
              resize: none;

              height: 120px;
              border-radius: 3px;
              border: solid 1px rgba(85, 83, 83, 0.1);
              background: #fff;
              // width: 280px;
              box-sizing: border-box;
              transition: all 0.3s linear;
              color: rgb(54, 54, 54);
              font-weight: 400;
              -webkit-appearance: none;
              &:focus {
                border: solid 1px $primary;
                outline: 0;
                box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
              }
            }
            label {
              font-size: 16px;
              display: block;
              color: rgb(59, 59, 59);
              opacity: 0.8;
              font-weight: 400;
            }
            button {
              -webkit-appearance: none;
              width: 100%;
              min-width: 100px;
              border-radius: 4px;
              text-align: center;
              padding: 10px 20px;
              margin-top: 5px;
              background-color: saturate($primary, 30%);
              color: #fff;
              font-size: 18px;
              margin-left: auto;
              font-weight: 400;
              box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
              border: none;
              transition: all 0.3s ease;
              outline: 0;
              &:hover {
                transform: translateY(-3px);
                box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
                &:active {
                  transform: scale(0.99);
                }
              }
            }
          }
        }
      }

      .contact-heading {
        margin: 20px 0px;
        .title {
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          font-weight: 600;
        }
        .subtitle {
          width: 100%;
          color: $primary;
          justify-content: center;
          align-items: center;
          display: flex;
          font-weight: 500;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .contact-container {
    overflow: hidden;
    height: auto;

    .contact-body {
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: center;
      align-items: flex-start;
      padding: 10px 60px;

      .contact-detail {
        display: flex;
        height: fit-content;
        width: 100%;
        border-radius: 5px;
        // justify-content: center;
        // gap: 20px;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: #fff;
        .imagebox {
          display: none;
        }
        .textbox {
          flex: 1;
          // margin-top: 20px;
          padding: 20px;
          // width: 100%;
          .contact {
            padding: 20px 10px;
            form {
              background: #fefefe;
              height: fit-content;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding-bottom: 20px;
              gap: 40px;
              width: fit-content;
            }
            .input-field {
              margin-bottom: 15px;
            }
            input {
              font-size: 16px;
              width: 100%;
              padding: 10px 10px;
              height: 35px;
              border-radius: 3px;
              border: solid 1px rgba(85, 83, 83, 0.1);
              background: #fff;
              // width: 280px;
              box-sizing: border-box;
              transition: all 0.3s linear;
              color: rgb(54, 54, 54);
              font-weight: 400;
              -webkit-appearance: none;
              &:focus {
                border: solid 1px $primary;
                outline: 0;
                box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
              }
            }
            textarea {
              font-size: 16px;
              width: 100%;
              padding: 8px 10px;

              margin-bottom: 10px;
              columns: 5;
              resize: none;

              height: 120px;
              border-radius: 3px;
              border: solid 1px rgba(85, 83, 83, 0.1);
              background: #fff;
              // width: 280px;
              box-sizing: border-box;
              transition: all 0.3s linear;
              color: rgb(54, 54, 54);
              font-weight: 400;
              -webkit-appearance: none;
              &:focus {
                border: solid 1px $primary;
                outline: 0;
                box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
              }
            }
            label {
              font-size: 16px;
              display: block;
              color: rgb(59, 59, 59);
              opacity: 0.8;
              font-weight: 400;
            }
            button {
              -webkit-appearance: none;
              width: 100%;
              min-width: 100px;
              border-radius: 4px;
              text-align: center;
              padding: 10px 20px;
              margin-top: 5px;
              background-color: saturate($primary, 30%);
              color: #fff;
              font-size: 18px;
              margin-left: auto;
              font-weight: 400;
              box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
              border: none;
              transition: all 0.3s ease;
              outline: 0;
              &:hover {
                transform: translateY(-3px);
                box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
                &:active {
                  transform: scale(0.99);
                }
              }
            }
          }
        }
      }

      .contact-heading {
        margin: 20px 0px;
        .title {
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          font-weight: 600;
        }
        .subtitle {
          width: 100%;
          color: $primary;
          justify-content: center;
          align-items: center;
          display: flex;
          font-weight: 500;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .contact-container {
    overflow: hidden;
    height: auto;

    .contact-body {
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: center;
      align-items: flex-start;
      padding: 10px 50px;

      .contact-detail {
        display: flex;
        height: fit-content;
        width: 100%;
        border-radius: 5px;
        // justify-content: center;
        // gap: 20px;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: #fff;
        .imagebox {
          display: none;
        }

        .textbox {
          flex: 1;
          margin-top: 0px;
          padding: 20px;
          // width: 100%;
          .contact {
            form {
              background: #fefefe;
              height: fit-content;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding-bottom: 20px;
              gap: 40px;
              width: fit-content;
            }
            .input-field {
              margin-bottom: 15px;
            }
            input {
              font-size: 12px;
              width: 100%;
              padding: 10px 7px;
              height: 30px;
              border-radius: 3px;
              border: solid 1px rgba(85, 83, 83, 0.1);
              background: #fff;
              // width: 280px;
              box-sizing: border-box;
              transition: all 0.3s linear;
              color: rgb(54, 54, 54);
              font-weight: 400;
              -webkit-appearance: none;
              &:focus {
                border: solid 1px $primary;
                outline: 0;
                box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
              }
            }
            textarea {
              font-size: 12px;
              width: 100%;
              padding: 10px 7px;
              margin-bottom: 10px;
              columns: 5;
              resize: none;

              height: 120px;
              border-radius: 3px;
              border: solid 1px rgba(85, 83, 83, 0.1);
              background: #fff;
              // width: 280px;
              box-sizing: border-box;
              transition: all 0.3s linear;
              color: rgb(54, 54, 54);
              font-weight: 400;
              -webkit-appearance: none;
              &:focus {
                border: solid 1px $primary;
                outline: 0;
                box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
              }
            }
            label {
              font-size: 12px;
              display: block;
              color: rgb(59, 59, 59);
              opacity: 0.8;
              font-weight: 400;
            }
            button {
              -webkit-appearance: none;
              width: 100%;
              min-width: 100px;
              border-radius: 4px;
              text-align: center;
              padding: 10px 20px;
              margin-top: 5px;
              background-color: saturate($primary, 30%);
              color: #fff;
              font-size: 12px;
              margin-left: auto;
              font-weight: 400;
              box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
              border: none;
              transition: all 0.3s ease;
              outline: 0;
              &:hover {
                transform: translateY(-3px);
                box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
                &:active {
                  transform: scale(0.99);
                }
              }
            }
          }
        }
      }

      .contact-heading {
        margin: 20px 0px;
        .title {
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          font-weight: 600;
        }
        .subtitle {
          width: 100%;
          color: $primary;
          justify-content: center;
          align-items: center;
          display: flex;
          font-weight: 500;
        }
      }
    }
  }
}
// @media screen and (max-width: 820px) {
//   .contact-container {
//     overflow: hidden;
//     height: auto;

//     .contact-body {
//       display: flex;
//       flex-direction: column;
//       height: fit-content;
//       justify-content: center;
//       padding: 10px 100px;

//       .contact-detail {
//         display: flex;
//         height: fit-content;
//         border-radius: 5px;
//         justify-content: center;
//         gap: 20px;
//         border-radius: 3px;
//         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
//         background-color: #fff;
//         .imagebox {
//           padding: 10px;
//           background-color: white;
//           flex: 0.6;
//           height: auto;
//           object-fit: cover;
//           overflow: hidden;
//           img {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//           }
//         }
//         .textbox {
//           flex: 0.4;
//           text-align: left;
//           padding-top: 10px;
//           color: rgb(29, 28, 28);
//           h3 {
//             font-weight: 500;
//           }
//           p {
//             margin-top: 5px;
//           }
//           .address {
//             margin-bottom: 20px;
//           }
//           .contact {
//             margin-bottom: 20px;
//           }
//         }
//         .social-media {
//           display: flex;
//           justify-content: start;
//           flex-direction: row;
//           gap: 6px;
//         }
//       }

//       .contact-heading {
//         margin: 20px 0px;
//         .title {
//           width: 100%;
//           justify-content: center;
//           align-items: center;
//           display: flex;
//           font-weight: 600;
//         }
//         .subtitle {
//           width: 100%;
//           color: $primary;
//           justify-content: center;
//           align-items: center;
//           display: flex;
//           font-weight: 500;
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 500px) {
//   .contact-container {
//     overflow: hidden;
//     height: auto;

//     .contact-body {
//       display: flex;
//       flex-direction: column;
//       height: fit-content;
//       justify-content: center;
//       padding: 10px 40px;

//       .contact-detail {
//         display: flex;
//         height: fit-content;
//         border-radius: 5px;
//         justify-content: center;
//         gap: 20px;
//         border-radius: 3px;
//         flex-direction: column;
//         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
//         background-color: #fff;
//         .imagebox {
//           background-color: white;
//           flex: 0.6;
//           height: auto;
//           height: 200px;
//           object-fit: cover;
//           overflow: hidden;
//           img {
//             width: 100%;
//             height: 200px;
//             object-fit: cover;
//           }
//         }
//         .textbox {
//           padding: 10px;
//           display: flex;
//           justify-content: center;
//           flex-direction: column;
//           align-items: center;
//           flex: 0.4;
//           text-align: left;
//           color: rgb(29, 28, 28);
//           text-align: center;

//           h3 {
//             font-weight: 500;
//           }
//           p {
//             margin-top: 5px;
//           }
//           .address {
//             margin-bottom: 20px;
//           }
//           .contact {
//             margin-bottom: 20px;
//           }
//         }
//         .social-media {
//           display: flex;
//           justify-content: start;
//           flex-direction: row;
//           gap: 6px;
//         }
//       }

//       .contact-heading {
//         margin: 20px 0px;
//         .title {
//           width: 100%;
//           justify-content: center;
//           align-items: center;
//           display: flex;
//           font-weight: 600;
//         }
//         .subtitle {
//           width: 100%;
//           color: $primary;
//           justify-content: center;
//           align-items: center;
//           display: flex;
//           font-weight: 500;
//         }
//       }
//     }
//   }
// }
