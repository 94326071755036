// .rug-design-container {
//   .rug-design-body {
//     padding: 40px 140px;
//   }
// }
.rug-design-container {
  .rug-design-body {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 40px 80px;
  }
}
// @media screen and (max-width: 1510px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 130px;
//     }
//   }
// }
// @media screen and (max-width: 1366px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 120px;
//     }
//   }
// }
// @media screen and (max-width: 1080px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 90px;
//     }
//   }
// }
// @media screen and (max-width: 940px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 80px;
//     }
//   }
// }
// @media screen and (max-width: 850px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 100px;
//     }
//   }
// }
// @media screen and (max-width: 768px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 90px;
//     }
//   }
// }
// @media screen and (max-width: 684px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 60px;
//     }
//   }
// }
// @media screen and (max-width: 625px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 60px;
//     }
//   }
// }
// @media screen and (max-width: 550px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 110px;
//     }
//   }
// }
// @media screen and (max-width: 490px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 100px;
//     }
//   }
// }
// @media screen and (max-width: 475px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 90px;
//     }
//   }
// }
// @media screen and (max-width: 450px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 80px;
//     }
//   }
// }

// @media screen and (max-width: 430px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 40px 60px;
//     }
//   }
// }

// @media screen and (max-width: 420px) {
//   .rug-design-container {
//     .rug-design-body {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       // padding: 40px 80px;
//     }
//   }
// }



// @media screen and (max-width: 340px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 30px 50px;
//     }
//   }
// }

// @media screen and (max-width: 320px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 20px 40px;
//     }
//   }
// }
// @media screen and (max-width: 300px) {
//   .rug-design-container {
//     .rug-design-body {
//       padding: 10px 30px;
//     }
//   }
// }
