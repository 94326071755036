$primary: #0099cc;
$darkgrey: #333333;

.about-container {
  overflow-x: hidden;
  height: auto;
  margin-bottom: 40px;

  .about-body {
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    padding: 10px 160px;

    .about-detail {
      display: flex;
      height: fit-content;
      border-radius: 5px;
      justify-content: center;
      gap: 20px;
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      .imagebox {
        background-color: white;
        flex: 0.4;
        height: auto;
        object-fit: cover;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .textbox {
        flex: 0.6;
        padding-right: 20px;
        padding-bottom: 10px;
        overflow-y: hidden;
        text-align: left;
        padding-top: 20px;
        justify-content: center;
        align-items: center;
        color: $darkgrey;
        p {
          text-align: justify;
          letter-spacing: 0.3px;
          margin-bottom: 10px;
        }
      }
    }
    .about-heading {
      margin: 20px 0px;
      .title {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        font-weight: 600;
      }
      .subtitle {
        width: 100%;
        justify-content: center;
        align-items: center;
        color: $primary;

        display: flex;
        font-weight: 500;
      }
    }
  }
}
@media screen and (max-width: 1150px) {
  .about-container {
    .about-body {
      padding: 10px 100px;
    }
  }
}
@media screen and (max-width: 920px) {
  .about-container {
    overflow-x: hidden;
    height: auto;

    .about-body {
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: center;
      padding: 10px 100px;

      .about-detail {
        display: flex;
        height: fit-content;
        border-radius: 5px;
        justify-content: center;
        gap: 20px;
        padding: 10px;
        background-color: #fff;
        flex-direction: column;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        .imagebox {
          background-color: white;
          flex: 0.6;
          height: auto;
          object-fit: cover;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .textbox {
          flex: 0.4;

          overflow-y: hidden;
          text-align: left;
          color: rgb(29, 28, 28);
        }
      }
      .about-heading {
        margin: 20px 0px;
        .title {
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          font-weight: 600;
        }
        .subtitle {
          width: 100%;
          justify-content: center;
          align-items: center;
          color: $primary;

          display: flex;
          font-weight: 500;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .about-container {
    overflow-x: hidden;
    height: auto;

    .about-body {
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: center;
      padding: 10px 40px;

      .about-detail {
        display: flex;
        height: fit-content;
        border-radius: 5px;
        justify-content: center;
        gap: 20px;
        padding: 10px;
        background-color: #fff;
        flex-direction: column;
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        .imagebox {
          background-color: white;
          flex: 0.6;
          height: auto;
          object-fit: cover;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .textbox {
          flex: 0.4;

          overflow-y: hidden;
          text-align: left;
          color: rgb(29, 28, 28);
        }
      }
      .about-heading {
        margin: 20px 0px;
        .title {
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          font-weight: 600;
        }
        .subtitle {
          width: 100%;
          justify-content: center;
          align-items: center;
          color: $primary;

          display: flex;
          font-weight: 500;
        }
      }
    }
  }
}
