$primary: #484545;
.cat-heading {
  margin: 30px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  .title {
    width: 100%;
    justify-content: center;
    color: $primary;
    align-items: center;
    display: flex;
    font-weight: 600;
  }
  .subtitle {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: rgb(126, 126, 126);
    font-weight: 400;
  }
}
.design-heading {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 4px;
  flex-direction: column;
  .title {
    font-weight: 600;
  }
  .subtitle {
    color: $primary;
    font-weight: 500;
  }
}
@media screen and (max-width: 414px) {
  .cat-heading {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .title {
      width: 100%;
      justify-content: center;
      color: $primary;
      align-items: center;
      display: flex;
      font-weight: 500;
      font-size: 18px;
    }
    .subtitle {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .design-heading {
    padding: 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 4px;
    flex-direction: column;
    .title {
      font-weight: 600;
      font-size: 22px;
    }
    .subtitle {
      color: $primary;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 300px) {
  .cat-heading {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .title {
      width: 100%;
      justify-content: center;
      color: $primary;
      align-items: center;
      display: flex;
      font-weight: 500;
      font-size: 16px;
    }
    .subtitle {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 12px;
      font-weight: 300;
    }
  }
}
