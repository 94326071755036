$stuble-black: #acacac;
$primary: #0099cc;
$lightgrey: #e6e6e6;
$darkgrey: #333333;

@font-face {
  font-family: "proxima_nova";
  font-display: swap;

  src: local("proxima_nova"),
    url(./assets/font/proxima-nova.otf) format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "proxima_nova", Helvetica;
}

body {
  // letter-spacing: 0.5px;
  // font-family: "bankgothic-regular";
  background-color: #f3f3f3;
}
.link {
  text-decoration: none !important;
}
.container {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid $stuble-black;
}
.button {
  background-color: #1396d6;
  color: white;
  border-radius: 2px;
  font-family: inherit;
  font-size: 1.3rem;
  outline: none;
  border: none;
  padding: 0.8rem;
  width: 100%;
  transition: transform 0.2s ease-in;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 2px 2px 5px 0px #cdc9d3;
  }

  &--action {
    padding: 0.2rem;
    font-size: 1rem;
    border-radius: 5px;

    &-2 {
      background-color: #9b2731;
    }
    &-1 {
      background-color: #868135;
      margin-right: 0.5rem;
    }
  }
}

.content {
  @media only screen and (min-width: 720px) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: 950px) {
    width: 950px;
  }
}
.icon-comp {
  padding: 0.5rem 1rem;
  img {
    width: 1.5rem;
    cursor: pointer;
  }
}

.error {
  font-size: 0.8rem;
  color: red;
}
.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
}

.previewDiv {
  margin-top: 20px;
  position: relative;
  width: 200px;

  .previewButton {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.previewImage {
  width: 200px;
  height: 200px;
  background-size: cover;
}
// verify page
.verify-container {
  display: flex;
  height: 60vh;
  justify-content: center;
  align-items: center;
  .exp-link {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    button {
      padding: 10px 60px;
      border-radius: 60px;
      border: none;
      cursor: pointer;
      color: white;
      font-size: 16px;
      background-color: $primary;
    }
  }
  .verified {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    button {
      padding: 10px 60px;
      border-radius: 60px;
      border: none;
      cursor: pointer;
      color: white;
      font-size: 16px;
      background-color: $primary;
    }
  }
}

//page-not-found
.page-not-found-container {
  margin-top: 40px;
  display: flex;
  height: 60vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .pnf-body {
    text-align: center;

    padding: 40px;
    width: fit-content;
    background-color: white;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);

    border-radius: 3px;
  }
}

.rug-improvised-detail {
  display: grid;
  gap: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  // flex-direction: row;
  // column-count: 4;
  // flex-wrap: wrap;
  width: 100%;
  // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
  grid-template-columns: repeat(4, 1fr);

  .card {
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    max-width: 280px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    height: 435px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    img {
      padding: 10px 10px 2px 10px;
      border-radius: 5px;
      height: 90%;
      object-fit: contain;

      width: 100%;
    }
    h3 {
      // padding-top: 8px;
    }
  }
  a {
    text-decoration: none;
    color: rgb(48, 47, 47);
  }
}
@media screen and (min-width: 1650px) {
  .rug-improvised-detail {
    display: grid;
    gap: 40px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(5, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 289px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 400px;
      width: 100%;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}
@media screen and (min-width: 1735px) {
  .rug-improvised-detail {
    display: grid;
    gap: 40px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(5, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 289px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 420px;
      width: 100%;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (min-width: 1880px) {
  .rug-improvised-detail {
    display: grid;
    gap: 40px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(5, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 289px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 450px;
      width: 100%;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (max-width: 1510px) {
  .rug-improvised-detail {
    display: grid;
    gap: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(4, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 270px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 400px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        // padding-top: 8px;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}
@media screen and (max-width: 1425px) {
  .rug-improvised-detail {
    display: grid;
    gap: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(4, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 250px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 380px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        // padding-top: 8px;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (max-width: 1366px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(4, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 240px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 360px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        // padding-top: 8px;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (max-width: 1280px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-columns: repeat(3, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 250px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 380px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}
@media screen and (max-width: 1080px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-columns: repeat(3, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 240px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 370px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (max-width: 940px) {
  .rug-improvised-detail {
    display: grid;
    gap: 25px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-columns: repeat(3, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 250px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 340px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        padding: 8px 8px 2px 8px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        font-size: 16px;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}
@media screen and (max-width: 850px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-columns: repeat(2, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 270px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 420px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        font-size: 16px;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}
@media screen and (max-width: 768px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-columns: repeat(2, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 240px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 370px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (max-width: 684px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-columns: repeat(2, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 240px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 370px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (max-width: 625px) {
  .rug-improvised-detail {
    display: grid;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-columns: repeat(2, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 220px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 320px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (max-width: 550px) {
  .rug-improvised-detail {
    display: grid;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-columns: repeat(1, 1fr);

    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 270px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 420px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}
@media screen and (max-width: 414px) {
  .rug-improvised-detail {
    display: grid;
    gap: 40px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(1, 1fr);
    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 250px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 390px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        padding: 8px 8px 2px 8px;

        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      // h3 {
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      // }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (max-width: 375px) {
  .rug-improvised-detail {
    display: grid;
    gap: 40px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(1, 1fr);
    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 250px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 385px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        padding: 8px 8px 2px 8px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (max-width: 360px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(1, 1fr);
    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 230px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 350px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        padding: 8px 8px 2px 8px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}
@media screen and (max-width: 340px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(1, 1fr);
    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 220px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 340px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        padding: 8px 8px 2px 8px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}
@media screen and (max-width: 320px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(1, 1fr);
    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 220px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 340px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        padding: 8px 8px 2px 8px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}
@media screen and (max-width: 300px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(1, 1fr);
    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 220px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 335px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        padding: 8px 8px 2px 8px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}

@media screen and (max-width: 280px) {
  .rug-improvised-detail {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    // flex-direction: row;
    // column-count: 4;
    // flex-wrap: wrap;
    width: 100%;
    // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
    grid-template-columns: repeat(1, 1fr);
    .card {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      max-width: 220px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: 335px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        padding: 8px 8px 2px 8px;
        border-radius: 5px;
        height: 90%;
        object-fit: contain;

        width: 100%;
      }
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    a {
      text-decoration: none;
      color: rgb(48, 47, 47);
    }
  }
}
