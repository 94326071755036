.latest-rugs {
  margin-top: 30px;
  h2 {
    color: #383a3b;
    font-weight: 500;
  }
  .rugs-section {
    position: relative;
    padding: 0px 80px;

    height: fit-content;
    .rug-details {
      display: grid;
      gap: 50px;
      // flex-direction: row;
      // column-count: 4;
      // flex-wrap: wrap;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      .card {
        cursor: pointer;
        background-color: rgb(255, 255, 255);
        border-radius: 3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        height: 450px;
        width: 100%;
        img {
          padding: 10px 10px 2px 10px;
          border-radius: 5px;
          height: 90%;
          object-fit: cover;

          width: 100%;
        }
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .latest-rugs {
    margin-top: 30px;
    h2 {
      color: #383a3b;
      // font-weight: 500;
    }
    .rugs-section {
      position: relative;
      padding: 0px 0px;

      height: fit-content;
      .rug-details {
        display: grid;
        gap: 50px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        .card {
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 450px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 415px) {
  .latest-rugs {
    margin-top: 30px;
    h2 {
      color: #383a3b;
      // font-weight: 500;
    }
    .rugs-section {
      position: relative;
      padding: 0px 0px;

      height: fit-content;
      .rug-details {
        display: grid;
        gap: 50px;
        // flex-direction: row;
        // column-count: 4;
        // flex-wrap: wrap;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        .card {
          cursor: pointer;
          background-color: rgb(255, 255, 255);
          border-radius: 3px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          height: 450px;
          width: 100%;
          img {
            padding: 10px 10px 2px 10px;
            border-radius: 5px;
            height: 90%;
            object-fit: cover;

            width: 100%;
          }
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
