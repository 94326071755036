$primary: #0099cc;
$darkgrey: #333333;

.privacy-container {
  overflow-x: hidden;
  height: auto;
  margin-bottom: 40px;
  padding: 50px 100px;
  h1{
    text-align: center;
    padding : 10px 0;
    font-size: 36px;
    color: $darkgrey;
  }
  p{
    padding : 5px 0;
  }

  h2{
    text-align: left;
    padding : 10px 0;
    color: $darkgrey;
    font-size: 22px;
  }
  
}
@media screen and (max-width: 1150px) {
  .privacy-container {
    padding: 40px 80px;
  }
}
@media screen and (max-width: 920px) {
  .privacy-container {
    overflow-x: hidden;
    height: auto;
    margin-bottom: 40px;
    padding: 50px 70px;
    h1{
      text-align: center;
      padding : 10px 0;
    color: $darkgrey;
    font-size: 36px;
    }
    p{
      padding : 5px 0;
    }
  
    h2{
      text-align: left;
      padding : 10px 0;
    color: $darkgrey;
    font-size: 22px;
    }
    
  }
}
@media screen and (max-width: 500px) {
  .privacy-container {
    overflow-x: hidden;
    height: auto;
    margin-bottom: 40px;
    padding: 40px 40px;
    h1{
      text-align: center;
      padding : 10px 0;
    color: $darkgrey;
    font-size: 32px;
    }
    p{
      padding : 5px 0;
    }
  
    h2{
      text-align: left;
      padding : 10px 0;
    color: $darkgrey;
    font-size: 20px;
    }
    
  }
}
