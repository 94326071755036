$primary: #0099cc;
.login-container {
  height: 90vh;
  padding: 20px 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  .session {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    height: fit-content;
    margin: auto auto;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
  }
}
.login-form {
  width: 450px;
  padding: 80px 40px;
  .title {
    font-size: 26px;
    font-weight: 500;
    opacity: 0.85;
  }
  .sub-title {
    font-size: 16px;
    color: $primary;
    font-weight: 400;
  }
  form {
    margin-top: 20px;
    background: #fefefe;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    width: fit-content;
  }
  input {
    font-size: 16px;
    width: 360px;
    padding: 20px 5px;
    margin-bottom: 10px;

    height: 35px;
    border-radius: 3px;
    border: solid 1px rgba(85, 83, 83, 0.1);
    background: #fff;
    // width: 280px;
    box-sizing: border-box;
    transition: all 0.3s linear;
    color: rgb(54, 54, 54);
    font-weight: 400;
    -webkit-appearance: none;
    &:focus {
      border: solid 1px $primary;
      outline: 0;
      box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
    }
  }

  label {
    font-size: 16px;
    display: block;
    color: rgb(59, 59, 59);
    opacity: 0.8;
    font-weight: 400;
  }
  .loadingBtn {
    -webkit-appearance: none;
    display: flex;
    justify-content: center;
    gap: 20px;

    width: 100%;
    min-width: 100px;
    border-radius: 4px;
    text-align: center;
    padding: 10px 20px;
    margin-top: 5px;
    background-color: saturate($primary, 30%);
    color: #fff;
    font-size: 18px;
    margin-left: auto;
    font-weight: 400;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
    border: none;
    transition: all 0.3s ease;
    outline: 0;
  }
  .loginBtn {
    -webkit-appearance: none;
    width: 100%;
    min-width: 100px;
    border-radius: 4px;
    text-align: center;
    padding: 10px 20px;
    margin-top: 5px;
    background-color: saturate($primary, 30%);
    color: #fff;
    font-size: 18px;
    margin-left: auto;
    font-weight: 400;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
    border: none;
    transition: all 0.3s ease;
    outline: 0;
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
      &:active {
        transform: scale(0.99);
      }
    }
  }
}

$displacement: 3px;
@keyframes shake-shake {
  0% {
    transform: translateX(-$displacement);
  }
  20% {
    transform: translateX($displacement);
  }
  40% {
    transform: translateX(-$displacement);
  }
  60% {
    transform: translateX($displacement);
  }
  80% {
    transform: translateX(-$displacement);
  }
  100% {
    transform: translateX(0px);
  }
}
#accept-policy {
  margin: 12px 0;
  font-size: 15px;
    color: rgb(94, 94, 94);
    text-decoration: none;
  span {
    font-weight: 400;
    text-decoration: none !important;
    color: $primary;
  }
}
#no-account {
  margin-top: 12px;
  font-size: 16px;
  text-decoration: none;
  span {
    font-weight: 400;
    text-decoration: none !important;
    color: $primary;
  }
}

.left {
  width: 350px;
  height: auto;
  position: relative;
  // background-image: url("../../assets/img/login.jpeg");
  // background-image: var(--image);
  background-size: cover;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

// .log-in {
//   padding: 40px 30px;
//   background: #fefefe;
//   height: 500px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   padding-bottom: 20px;
//   width: 200px;
//   h4 {
//     font-size: 32px;
//     font-weight: 600;
//     color: #000;
//     opacity: 0.85;
//   }
//   label {
//     font-size: 16px;
//     display: block;
//     color: rgb(59, 59, 59);
//     opacity: 0.8;
//     font-weight: 400;
//   }
//   p {
//     line-height: 15%;
//     margin-bottom: 5px;
//     font-size: 16px;
//     color: $primary;
//     font-weight: 400;
//     // max-width: 200px;
//     margin-bottom: 40px;
//   }
//   .input-field {
//     margin-bottom: 10px;
//   }
// }
a.discrete {
  color: rgba($primary, 0.8);
  font-size: 14px;
  padding-bottom: 4px;
  cursor: pointer;
  text-decoration: none;
  margin-left: auto;
  font-weight: 400;
  transition: all 0.3s ease;
  margin-top: 5px;
}
// button {
//   -webkit-appearance: none;
//   width: 400px;
//   min-width: 100px;
//   border-radius: 4px;
//   text-align: center;
//   padding: 10px 20px;
//   margin-top: 5px;
//   background-color: saturate($primary, 30%);
//   color: #fff;
//   font-size: 18px;
//   margin-left: auto;
//   font-weight: 400;
//   box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
//   border: none;
//   transition: all 0.3s ease;
//   outline: 0;
//   &:hover {
//     transform: translateY(-3px);
//     box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
//     &:active {
//       transform: scale(0.99);
//     }
//   }
// }
// input {
//   font-size: 16px;
//   padding: 20px 5px;
//   height: 35px;
//   width: 350px;
//   border-radius: 3px;
//   border: solid 1px rgba(85, 83, 83, 0.1);
//   background: #fff;
//   // width: 280px;
//   box-sizing: border-box;
//   transition: all 0.3s linear;
//   color: rgb(54, 54, 54);
//   font-weight: 400;
//   -webkit-appearance: none;
//   &:focus {
//     border: solid 1px $primary;
//     outline: 0;
//     box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
//   }
// }

// $displacement: 3px;
// @keyframes shake-shake {
//   0% {
//     transform: translateX(-$displacement);
//   }
//   20% {
//     transform: translateX($displacement);
//   }
//   40% {
//     transform: translateX(-$displacement);
//   }
//   60% {
//     transform: translateX($displacement);
//   }
//   80% {
//     transform: translateX(-$displacement);
//   }
//   100% {
//     transform: translateX(0px);
//   }
// }
// #no-account {
//   margin-top: 12px;
//   font-size: 16px;
//   text-decoration: none;
//   span {
//     font-weight: 400;
//     text-decoration: none !important;
//     color: $primary;
//   }
// }

// .container {
//   display: flex;
//   flex-direction: row-reverse;
//   width: auto;
//   height: fit-content;
//   margin: auto auto;
//   background: #ffffff;
//   border-radius: 4px;
//   box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
// }
// .left {
//   width: 350px;
//   height: auto;
//   min-height: 100%;
//   position: relative;
//   background-image: url("./login.jpeg");
//   background-size: cover;
//   border-top-left-radius: 4px;
//   border-bottom-left-radius: 4px;
//   svg {
//     height: 40px;
//     width: auto;
//     margin: 20px;
//   }
// }
.circular-loader {
  color: #fefefe !important;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 862px) {
  .login-container {
    height: fit-content;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .session {
      display: flex;
      flex-direction: row-reverse;
      width: fit-content;
      height: fit-content;
      margin: auto auto;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    }
  }
  .login-form {
    width: 100%;
    padding: 80px 40px;
    .title {
      font-size: 26px;
      font-weight: 500;
      opacity: 0.85;
    }
    .sub-title {
      font-size: 16px;
      color: $primary;
      font-weight: 400;
    }
    form {
      margin-top: 20px;
      background: #fefefe;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
      width: fit-content;
    }
    input {
      font-size: 16px;
      width: 360px;
      padding: 20px 5px;
      margin-bottom: 10px;

      height: 35px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }

    label {
      font-size: 16px;
      display: block;
      color: rgb(59, 59, 59);
      opacity: 0.8;
      font-weight: 400;
    }
    .loginBtn {
      -webkit-appearance: none;
      width: 100%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 18px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }
  .left {
    display: none;
    width: 350px;
    height: auto;
    position: relative;
    // background-image: url("../../assets/img/login.jpeg");
    background-size: cover;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  $displacement: 3px;
  @keyframes shake-shake {
    0% {
      transform: translateX(-$displacement);
    }
    20% {
      transform: translateX($displacement);
    }
    40% {
      transform: translateX(-$displacement);
    }
    60% {
      transform: translateX($displacement);
    }
    80% {
      transform: translateX(-$displacement);
    }
    100% {
      transform: translateX(0px);
    }
  }
  #no-account {
    margin-top: 12px;
    font-size: 16px;
    text-decoration: none;
    span {
      font-weight: 400;
      text-decoration: none !important;
      color: $primary;
    }
  }

  a.discrete {
    color: rgba($primary, 0.8);
    font-size: 14px;
    padding-bottom: 4px;
    text-decoration: none;
    margin-left: auto;
    font-weight: 400;
    transition: all 0.3s ease;
    margin-top: 5px;
  }
}

@media screen and (max-width: 862px) {
  .login-container {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    .session {
      display: flex;
      flex-direction: row-reverse;
      width: fit-content;
      height: fit-content;
      // margin: auto auto;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    }
  }
  .login-form {
    width: 100%;
    padding: 30px 20px;
    .title {
      font-size: 24px;
      font-weight: 500;
      opacity: 0.85;
    }
    .sub-title {
      font-size: 14px;
      color: $primary;
      font-weight: 400;
    }
    form {
      margin-top: 20px;
      background: #fefefe;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
      width: fit-content;
    }
    input {
      font-size: 16px;
      width: 270px;
      padding: 20px 5px;
      margin-bottom: 10px;

      height: 35px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }

    label {
      font-size: 14px;
      display: block;
      color: rgb(59, 59, 59);
      opacity: 0.8;
      font-weight: 400;
    }
    .loginBtn {
      -webkit-appearance: none;
      width: 100%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 18px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }
  .left {
    display: none;
    width: 350px;
    height: auto;
    position: relative;
    // background-image: url("../../assets/img/login.jpeg");
    background-size: cover;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  $displacement: 3px;
  @keyframes shake-shake {
    0% {
      transform: translateX(-$displacement);
    }
    20% {
      transform: translateX($displacement);
    }
    40% {
      transform: translateX(-$displacement);
    }
    60% {
      transform: translateX($displacement);
    }
    80% {
      transform: translateX(-$displacement);
    }
    100% {
      transform: translateX(0px);
    }
  }
  #no-account {
    margin-top: 12px;
    font-size: 16px;
    text-decoration: none;
    span {
      font-weight: 400;
      text-decoration: none !important;
      color: $primary;
    }
  }

  a.discrete {
    color: rgba($primary, 0.8);
    font-size: 14px;
    padding-bottom: 4px;
    text-decoration: none;
    margin-left: auto;
    font-weight: 400;
    transition: all 0.3s ease;
    margin-top: 5px;
  }
}

@media screen and (max-width: 375px) {
  .login-container {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    .session {
      display: flex;
      flex-direction: row-reverse;
      width: fit-content;
      height: fit-content;
      // margin: auto auto;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    }
  }
  .login-form {
    width: 100%;
    padding: 30px 20px;
    .title {
      font-size: 24px;
      font-weight: 500;
      opacity: 0.85;
    }
    .sub-title {
      font-size: 14px;
      color: $primary;
      font-weight: 400;
    }
    form {
      margin-top: 20px;
      background: #fefefe;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
      width: fit-content;
    }
    input {
      font-size: 12px;
      width: 270px;
      padding: 20px 5px;
      margin-bottom: 10px;

      height: 30px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }

    label {
      font-size: 14px;
      display: block;
      color: rgb(59, 59, 59);
      opacity: 0.8;
      font-weight: 400;
    }
    .loginBtn {
      -webkit-appearance: none;
      width: 100%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 18px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }
  .left {
    display: none;
    width: 350px;
    height: auto;
    position: relative;
    // background-image: url("../../assets/img/login.jpeg");
    background-size: cover;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  $displacement: 3px;
  @keyframes shake-shake {
    0% {
      transform: translateX(-$displacement);
    }
    20% {
      transform: translateX($displacement);
    }
    40% {
      transform: translateX(-$displacement);
    }
    60% {
      transform: translateX($displacement);
    }
    80% {
      transform: translateX(-$displacement);
    }
    100% {
      transform: translateX(0px);
    }
  }
  #no-account {
    margin-top: 12px;
    font-size: 16px;
    text-decoration: none;
    span {
      font-weight: 400;
      text-decoration: none !important;
      color: $primary;
    }
  }

  a.discrete {
    color: rgba($primary, 0.8);
    font-size: 14px;
    padding-bottom: 4px;
    text-decoration: none;
    margin-left: auto;
    font-weight: 400;
    transition: all 0.3s ease;
    margin-top: 5px;
  }
}

@media screen and (max-width: 280px) {
  .login-container {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    .session {
      display: flex;
      flex-direction: row-reverse;
      width: fit-content;
      height: fit-content;
      // margin: auto auto;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
    }
  }
  .login-form {
    width: 100%;
    padding: 30px 20px;
    margin: 0px 20px;
    .title {
      font-size: 20px;
      font-weight: 500;
      opacity: 0.85;
    }
    .sub-title {
      font-size: 12px;
      color: $primary;
      font-weight: 400;
    }
    form {
      margin-top: 20px;
      background: #fefefe;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
      width: fit-content;
    }
    input {
      font-size: 11px;
      width: 240px;
      padding: 20px 5px;
      margin-bottom: 10px;

      height: 20px;
      border-radius: 3px;
      border: solid 1px rgba(85, 83, 83, 0.1);
      background: #fff;
      // width: 280px;
      box-sizing: border-box;
      transition: all 0.3s linear;
      color: rgb(54, 54, 54);
      font-weight: 400;
      -webkit-appearance: none;
      &:focus {
        border: solid 1px $primary;
        outline: 0;
        box-shadow: 0 2px 6px -8px rgba($primary, 0.45);
      }
    }

    label {
      font-size: 12px;
      display: block;
      color: rgb(59, 59, 59);
      opacity: 0.8;
      font-weight: 400;
    }
    .loginBtn {
      -webkit-appearance: none;
      width: 100%;
      min-width: 100px;
      border-radius: 4px;
      text-align: center;
      padding: 10px 20px;
      margin-top: 5px;
      background-color: saturate($primary, 30%);
      color: #fff;
      font-size: 18px;
      margin-left: auto;
      font-weight: 400;
      box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
      border: none;
      transition: all 0.3s ease;
      outline: 0;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 2px 6px -1px rgba($primary, 0.65);
        &:active {
          transform: scale(0.99);
        }
      }
    }
  }
  .left {
    display: none;
    width: 350px;
    height: auto;
    position: relative;
    // background-image: url("../../assets/img/login.jpeg");
    background-size: cover;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  $displacement: 3px;
  @keyframes shake-shake {
    0% {
      transform: translateX(-$displacement);
    }
    20% {
      transform: translateX($displacement);
    }
    40% {
      transform: translateX(-$displacement);
    }
    60% {
      transform: translateX($displacement);
    }
    80% {
      transform: translateX(-$displacement);
    }
    100% {
      transform: translateX(0px);
    }
  }
  #no-account {
    margin-top: 11px;
    font-size: 16px;
    text-decoration: none;
    span {
      font-weight: 400;
      text-decoration: none !important;
      color: $primary;
    }
  }
  #accept-policy {
    margin: 11px 0;
    font-size: 15px;
      color: rgb(94, 94, 94);
      text-decoration: none;
    span {
      font-weight: 400;
      text-decoration: none !important;
      color: $primary;
    }
  }
  a.discrete {
    color: rgba($primary, 0.8);
    font-size: 11px;
    padding-bottom: 4px;
    text-decoration: none;
    margin-left: auto;
    font-weight: 400;
    transition: all 0.3s ease;
    margin-top: 5px;
  }
}
