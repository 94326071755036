$primary: #0099cc;
.design-container {
  height: auto;
  .design-body {
    padding: 0px 120px;

    // .design-heading {
    //   margin: 20px 0px;
    //   .title {
    //     width: 100%;
    //     justify-content: center;
    //     align-items: center;
    //     display: flex;
    //     font-weight: 600;
    //   }
    //   .subtitle {
    //     width: 100%;
    //     justify-content: center;
    //     align-items: center;
    //     color: $primary;
    //     display: flex;
    //     font-weight: 500;
    //   }
    // }
    .design-categories {
      display: flex;
      justify-content: flex-start;
      gap: 15px;
      overflow: hidden;
      #next,
      #prev {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        // top: 50%;
        // transform: translateY(118%);
        z-index: 5;
        background-color: transparent;
        border: none;
        color: $primary;
        cursor: pointer;
      }
      #prev {
        left: 110px;
      }
      #next {
        right: 110px;
      }
      .cat-list {
        // background-color: rgb(236, 236, 236);
        // padding: 8px 18px;

        // overflow-x: scroll;
        // overflow-y: hidden;
        .tabbed-category {
          background-color: $primary;
          color: white;
          padding: 8px 20px;
          margin-right: 10px;
          font-size: 15px;
          border-radius: 1px;
          width: 150px;
          height: auto;
          border: none;
        }
        .active {
          background-color: transparent;
          color: $primary;
          border: 1px solid $primary;
          border-bottom: none;
        }
      }
    }
  }
}

.rug-details {
  display: grid;
  gap: 40px;
  margin-top: 30px;
  // flex-direction: row;
  // column-count: 4;
  // flex-wrap: wrap;
  width: 100%;
  // grid-template-columns: repeat(auto-fit, minmax(4, 1fr));
  grid-template-columns: repeat(4, 1fr);

  .card {
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    max-width: 250px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    height: 420px;
    width: 100%;
    img {
      padding: 10px 10px 2px 10px;
      border-radius: 5px;
      height: 90%;
      object-fit: cover;

      width: 100%;
    }
    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  a {
    text-decoration: none;
    color: rgb(48, 47, 47);
  }
}
.tabbed-content {
  margin-top: 40px;
}

@media screen and (max-width: 1366px) {
  .design-container {
    .design-body {
      padding: 0px 100px;
    }

    .tabbed-content {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .design-container {
    .design-body {
      padding: 0px 140px;
    }
    .rug-details {
      display: grid;
      gap: 40px;
      margin-top: 30px;
      // flex-direction: row;
      // column-count: 4;
      // flex-wrap: wrap;
      width: 100%;
      // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
      grid-template-columns: repeat(3, 1fr);

      .card {
        background-color: rgb(255, 255, 255);
        border-radius: 3px;
        max-width: 250px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        height: 380px;
        width: 100%;
        img {
          padding: 10px 10px 2px 10px;
          border-radius: 5px;
          height: 90%;
          object-fit: cover;

          width: 100%;
        }
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      a {
        text-decoration: none;
        color: rgb(48, 47, 47);
      }
    }
    .tabbed-content {
      margin-top: 40px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .design-container {
    .design-body {
      padding: 0px 60px;
    }
    .rug-details {
      display: grid;
      gap: 50px;
      margin-top: 30px;
      // flex-direction: row;
      // column-count: 4;
      // flex-wrap: wrap;
      width: 100%;
      // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
      grid-template-columns: repeat(3, 1fr);

      .card {
        background-color: rgb(255, 255, 255);
        border-radius: 3px;
        max-width: 250px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        height: 380px;
        width: 100%;
        img {
          padding: 10px 10px 2px 10px;
          border-radius: 5px;
          height: 90%;
          object-fit: cover;

          width: 100%;
        }
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      a {
        text-decoration: none;
        color: rgb(48, 47, 47);
      }
    }
    .tabbed-content {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 940px) {
  .design-container {
    .design-body {
      padding: 0px 60px;
    }
    .rug-details {
      display: grid;
      gap: 50px;
      margin-top: 30px;
      // flex-direction: row;
      // column-count: 4;
      // flex-wrap: wrap;
      width: 100%;
      // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
      grid-template-columns: repeat(3, 1fr);

      .card {
        background-color: rgb(255, 255, 255);
        border-radius: 3px;
        max-width: 250px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        height: 380px;
        width: 100%;
        img {
          padding: 10px 10px 2px 10px;
          border-radius: 5px;
          height: 90%;
          object-fit: cover;

          width: 100%;
        }
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      a {
        text-decoration: none;
        color: rgb(48, 47, 47);
      }
    }
    .tabbed-content {
      margin-top: 40px;
    }
  }
}

//
// ipad screen
@media screen and (max-width: 768px) {
  .design-container {
    .design-body {
      padding: 0px 120px;
    }
    .rug-details {
      display: grid;
      gap: 40px;
      margin-top: 30px;
      width: 100%;
      // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
      grid-template-columns: repeat(2, 1fr);

      .card {
        background-color: rgb(255, 255, 255);
        border-radius: 3px;
        max-width: 250px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        height: 360px;
        width: 100%;
        img {
          padding: 10px 10px 2px 10px;
          border-radius: 5px;
          height: 90%;
          object-fit: cover;

          width: 100%;
        }
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      a {
        text-decoration: none;
        color: rgb(48, 47, 47);
      }
    }
    .tabbed-content {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 650px) {
  .design-container {
    .design-body {
      padding: 0px 70px;
    }
    .rug-details {
      display: grid;
      gap: 40px;
      margin-top: 30px;
      width: 100%;
      // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
      grid-template-columns: repeat(2, 1fr);

      .card {
        background-color: rgb(255, 255, 255);
        border-radius: 3px;
        max-width: 250px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        height: 360px;
        width: 100%;
        img {
          padding: 10px 10px 2px 10px;
          border-radius: 5px;
          height: 90%;
          object-fit: cover;

          width: 100%;
        }
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      a {
        text-decoration: none;
        color: rgb(48, 47, 47);
      }
    }
    .tabbed-content {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 540px) {
  .design-container {
    height: auto;
    .design-body {
      padding: 0px 40px;

      .design-categories {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        overflow: hidden;
        #next,
        #prev {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          // top: 50%;
          // transform: translateY(118%);
          z-index: 5;
          background-color: transparent;
          border: none;
          color: $primary;
          cursor: pointer;
        }
        #prev {
          left: 110px;
        }
        #next {
          right: 110px;
        }
        .cat-list {
          // background-color: rgb(236, 236, 236);
          // padding: 8px 18px;

          // overflow-x: scroll;
          // overflow-y: hidden;
          .tabbed-category {
            background-color: $primary;
            color: white;
            padding: 8px 10px;
            margin-right: 10px;
            font-size: 13px;
            border-radius: 1px;
            width: 125px;
            height: auto;
            border: none;
          }
          .active {
            background-color: transparent;
            color: $primary;
            border: 1px solid $primary;
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .design-container {
    height: auto;
    .design-body {
      padding: 0px 90px;

      .design-categories {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        overflow: hidden;
        #next,
        #prev {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          // top: 50%;
          // transform: translateY(118%);
          z-index: 5;
          background-color: transparent;
          border: none;
          color: $primary;
          cursor: pointer;
        }
        #prev {
          left: 110px;
        }
        #next {
          right: 110px;
        }
        .cat-list {
          // background-color: rgb(236, 236, 236);
          // padding: 8px 18px;

          // overflow-x: scroll;
          // overflow-y: hidden;
          .tabbed-category {
            background-color: $primary;
            color: white;
            padding: 8px 10px;
            margin-right: 10px;
            font-size: 12px;
            border-radius: 1px;
            width: 110px;
            height: auto;
            border: none;
          }
          .active {
            background-color: transparent;
            color: $primary;
            border: 1px solid $primary;
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .design-container {
    height: auto;
    .design-body {
      padding: 0px 70px;

      .design-categories {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        overflow: hidden;
        #next,
        #prev {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          // top: 50%;
          // transform: translateY(118%);
          z-index: 5;
          background-color: transparent;
          border: none;
          color: $primary;
          cursor: pointer;
        }
        #prev {
          left: 110px;
        }
        #next {
          right: 110px;
        }
        .cat-list {
          // background-color: rgb(236, 236, 236);
          // padding: 8px 18px;

          // overflow-x: scroll;
          // overflow-y: hidden;
          .tabbed-category {
            background-color: $primary;
            color: white;
            padding: 8px 10px;
            margin-right: 10px;
            font-size: 12px;
            border-radius: 1px;
            width: 110px;
            height: auto;
            border: none;
          }
          .active {
            background-color: transparent;
            color: $primary;
            border: 1px solid $primary;
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .design-container {
    height: auto;
    .design-body {
      padding: 0px 50px;

      .design-categories {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        overflow: hidden;
        #next,
        #prev {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          // top: 50%;
          // transform: translateY(118%);
          z-index: 5;
          background-color: transparent;
          border: none;
          color: $primary;
          cursor: pointer;
        }
        #prev {
          left: 110px;
        }
        #next {
          right: 110px;
        }
        .cat-list {
          // background-color: rgb(236, 236, 236);
          // padding: 8px 18px;

          // overflow-x: scroll;
          // overflow-y: hidden;
          .tabbed-category {
            background-color: $primary;
            color: white;
            padding: 8px 10px;
            margin-right: 5px;
            font-size: 12px;
            border-radius: 1px;
            width: 160px;
            height: auto;
            border: none;
          }
          .active {
            background-color: transparent;
            color: $primary;
            border: 1px solid $primary;
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .design-container {
    height: auto;
    .design-body {
      padding: 0px 40px;

      .design-categories {
        display: flex;
        justify-content: flex-start;
        gap: 2px;
        overflow: hidden;
        #next,
        #prev {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          // top: 50%;
          // transform: translateY(118%);
          z-index: 5;
          background-color: transparent;
          border: none;
          color: $primary;
          cursor: pointer;
        }
        #prev {
          left: 110px;
        }
        #next {
          right: 110px;
        }
        .cat-list {
          // background-color: rgb(236, 236, 236);
          // padding: 8px 18px;

          // overflow-x: scroll;
          // overflow-y: hidden;
          .tabbed-category {
            background-color: $primary;
            color: white;
            padding: 8px 10px;
            margin-right: 5px;
            font-size: 12px;
            border-radius: 1px;
            width: 180px;
            height: auto;
            border: none;
          }
          .active {
            background-color: transparent;
            color: $primary;
            border: 1px solid $primary;
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 280px) {
  .design-container {
    height: auto;
    .design-body {
      padding: 0px 40px;

      .design-categories {
        display: flex;
        justify-content: flex-start;
        gap: 2px;
        overflow: hidden;
        #next,
        #prev {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          // top: 50%;
          // transform: translateY(118%);
          z-index: 5;
          background-color: transparent;
          border: none;
          color: $primary;
          cursor: pointer;
        }
        #prev {
          left: 110px;
        }
        #next {
          right: 110px;
        }
        .cat-list {
          // background-color: rgb(236, 236, 236);
          // padding: 8px 18px;

          // overflow-x: scroll;
          // overflow-y: hidden;
          .tabbed-category {
            background-color: $primary;
            color: white;
            padding: 8px 10px;
            margin-right: 5px;
            font-size: 12px;
            border-radius: 1px;
            width: 160px;
            height: auto;
            border: none;
          }
          .active {
            background-color: transparent;
            color: $primary;
            border: 1px solid $primary;
            border-bottom: none;
          }
        }
      }
    }
  }
}
