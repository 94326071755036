.banner-design {
  height: 30vh;
  overflow: hidden;
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.2)
  );
  // background: rgba(0, 0, 0, 0.3);
  // background: radial-gradient(
  //   rgba(0, 0, 0, 0.4),
  //   rgba(0, 0, 0, 0.3),
  //   rgba(0, 0, 0, 0.2),
  //   rgba(0, 0, 0, 0.1)
  // );
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .banner-text {
    // position: absolute;
    position: relative;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    letter-spacing: 1px;

    h1 {
      color: white;
      font-weight: 600;
    }
    p {
      display: flex;
      justify-content: center;
      color: white;
      font-weight: 400;
    }
    .icon {
      height: 60px;
      width: 60px;
      object-fit: contain;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 520px) {
  .banner-design {
    height: 30vh;
    overflow: hidden;
    position: relative;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2)
    );
    // background: rgba(0, 0, 0, 0.3);
    // background: radial-gradient(
    //   rgba(0, 0, 0, 0.4),
    //   rgba(0, 0, 0, 0.3),
    //   rgba(0, 0, 0, 0.2),
    //   rgba(0, 0, 0, 0.1)
    // );
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .banner-text {
      // position: absolute;
      position: relative;
      height: 100%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      letter-spacing: 1px;

      h1 {
        color: white;
        font-weight: 600;
        font-size: 28px;
      }
      p {
        display: flex;
        font-size: 14px;
        justify-content: center;
        color: white;
        font-weight: 400;
      }
      .icon {
        height: 50px;
        width: 50px;
        object-fit: contain;
        margin-bottom: 10px;
      }
    }
  }
  // .banner-design {
  //   height: 30vh;
  //   overflow: hidden;
  //   position: relative;
  //   // background: linear-gradient(
  //   //   90deg,
  //   //   rgba(0, 0, 0, 0.2),
  //   //   rgba(0, 0, 0, 0.3),
  //   //   rgba(0, 0, 0, 0.5),
  //   //   rgba(0, 0, 0, 0.3),
  //   //   rgba(0, 0, 0, 0.2) !important
  //   // );
  //   background: rgba(0, 0, 0, 0.2) !important;
  //   .bg-image {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     object-fit: cover;
  //     width: 100%;
  //     height: 100%;
  //     z-index: 1;
  //   }
  //   .banner-text {
  //     // position: absolute;
  //     position: relative;
  //     height: 100%;
  //     z-index: 2;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;
  //     gap: 10px;
  //     letter-spacing: 1px;
  //     h1 {
  //       color: white;
  //       font-weight: 600;
  //       font-size: 28px;
  //     }
  //     p {
  //       display: flex;
  //       font-size: 14px;
  //       justify-content: center;
  //       color: white;
  //       font-weight: 400;
  //     }
  //   }
  // }
}

@media screen and (max-width: 414px) {
  .banner-design {
    height: 30vh;
    overflow: hidden;
    position: relative;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2)
    );
    // background: rgba(0, 0, 0, 0.3);
    // background: radial-gradient(
    //   rgba(0, 0, 0, 0.4),
    //   rgba(0, 0, 0, 0.3),
    //   rgba(0, 0, 0, 0.2),
    //   rgba(0, 0, 0, 0.1)
    // );
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .banner-text {
      // position: absolute;
      position: relative;
      height: 100%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      letter-spacing: 1px;
      h1 {
        color: white;
        font-weight: 600;
        font-size: 24px;
      }
      p {
        display: flex;
        font-size: 12px;
        text-align: center;
        color: white;
        font-weight: 400;
      }

      .icon {
        height: 40px;
        width: 40px;
        object-fit: contain;
        margin-bottom: 10px;
      }
    }
  }
  // .banner-design {
  //   height: 200px;
  //   overflow: hidden;
  //   position: relative;
  //   background: linear-gradient(
  //     90deg,
  //     rgba(0, 0, 0, 0.2),
  //     rgba(0, 0, 0, 0.3),
  //     rgba(0, 0, 0, 0.5),
  //     rgba(0, 0, 0, 0.3),
  //     rgba(0, 0, 0, 0.2)
  //   );
  //   .bg-image {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     object-fit: cover;
  //     width: 100%;
  //     height: 100%;
  //     z-index: 1;
  //   }
  //   .banner-text {
  //     // position: absolute;
  //     position: relative;
  //     height: 100%;
  //     z-index: 2;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;
  //     h1 {
  //       color: white;
  //       font-weight: 600;
  //       font-size: 24px;
  //     }
  //     p {
  //       display: flex;
  //       font-size: 12px;
  //       text-align: center;
  //       color: white;
  //       font-weight: 400;
  //     }
  //   }
  // }
}

@media screen and (max-width: 330px) {
  // .banner-design {
  //   height: 200px;
  //   overflow: hidden;
  //   position: relative;
  //   background: linear-gradient(
  //     90deg,
  //     rgba(0, 0, 0, 0.2),
  //     rgba(0, 0, 0, 0.3),
  //     rgba(0, 0, 0, 0.5),
  //     rgba(0, 0, 0, 0.3),
  //     rgba(0, 0, 0, 0.2)
  //   );
  //   .bg-image {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     object-fit: cover;
  //     width: 100%;
  //     height: 100%;
  //     z-index: 1;
  //   }
  //   .banner-text {
  //     // position: absolute;
  //     position: relative;
  //     height: 100%;
  //     z-index: 2;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;
  //     padding: 0 15px;
  //     h1 {
  //       color: white;
  //       font-weight: 600;
  //       font-size: 22px;
  //     }
  //     p {
  //       display: flex;
  //       font-size: 11px;
  //       text-align: center;
  //       color: white;
  //       font-weight: 400;
  //     }
  //   }
  // }
  .banner-design {
    height: 30vh;
    overflow: hidden;
    position: relative;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2)
    );
    // background: rgba(0, 0, 0, 0.3);
    // background: radial-gradient(
    //   rgba(0, 0, 0, 0.4),
    //   rgba(0, 0, 0, 0.3),
    //   rgba(0, 0, 0, 0.2),
    //   rgba(0, 0, 0, 0.1)
    // );
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .banner-text {
      // position: absolute;
      position: relative;
      height: 100%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      letter-spacing: 1px;
      h1 {
        color: white;
        font-weight: 600;
        font-size: 24px;
      }
      p {
        display: flex;
        font-size: 12px;
        text-align: center;
        color: white;
        font-weight: 400;
      }

      .icon {
        height: 40px;
        width: 40px;
        object-fit: contain;
        margin-bottom: 10px;
      }
    }
  }
  
}
