$primary: #484545;
.footer-container {
  // margin-top: 40px;
  .top-footer {
    padding: 50px 80px;

    background-color: #969696;

    color: #fff;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    gap: 100px;
    .about-footer {
      flex: 0.5;
      img {
        height: 80px;
        object-fit: contain;
        margin-bottom: 15px;
      }
      p {
        text-align: justify;
        line-height: 1.5;
      }
    }
    .quick-link {
      flex: 0.1;
      .links {
        margin-top: 20px;

        a {
          text-decoration: none;
          margin-top: 15px;
          color: white;
        }
      }
      h3 {
        color: $primary !important;
      }

      p {
        padding: 10px 0px;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .designs-link {
      flex: 0.1;
      .links {
        margin-top: 20px;

        a {
          text-decoration: none;
          margin-top: 15px;
          color: white;
        }
      }
      h3 {
        color: $primary !important;
      }

      p {
        padding: 10px 0px;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .contact-footer {
      flex: 0.2;
      h3 {
        color: $primary !important;
      }
      .location-detail {
        margin-top: 25px;
        h2 {
          margin-top: 15px;
          color: $primary;
          font-size: 22px;
        }
      }
    }
  }
  .bottom-footer {
    background-color: #6b6b6b ;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }
  max-height: 50vh;
  width: 100%;
}
@media screen and (max-width: 950px) {
  .footer-container {
    // margin-top: 40px;
    .top-footer {
      padding: 50px 40px;

      background-color: #969696;

      color: #fff;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
      gap: 30px;
      .about-footer {
        flex: 0.5;
        img {
          height: 80px;
          object-fit: contain;
          margin-bottom: 15px;
        }
        p {
          text-align: justify;
          line-height: 1.5;
        }
      }
      .quick-link {
        flex: 0.1;
        .links {
          margin-top: 20px;

          a {
            text-decoration: none;
            margin-top: 15px;
            color: white;
          }
        }
        h3 {
          color: $primary !important;
        }

        p {
          padding: 10px 0px;
          cursor: pointer;
          text-decoration: none;
        }
      }
      .designs-link {
        flex: 0.1;
        .links {
          margin-top: 20px;

          a {
            text-decoration: none;
            margin-top: 15px;
            color: white;
          }
        }
        h3 {
          color: $primary !important;
        }

        p {
          padding: 10px 0px;
          cursor: pointer;
          text-decoration: none;
        }
      }
      .contact-footer {
        flex: 0.2;
        h3 {
          color: $primary !important;
        }
        .location-detail {
          margin-top: 25px;
          h2 {
            margin-top: 15px;
            color: $primary;
            font-size: 22px;
          }
        }
      }
    }
    .bottom-footer {
    background-color: #6b6b6b ;
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      height: 10vh;
    }
    max-height: 50vh;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .footer-container {
    padding-top: 40px;
    .top-footer {
      padding: 40px;

      background-color: #969696;

      color: #fff;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
      gap: 70px;
      .about-footer {
        flex: 1;
        img {
          height: 80px;
          object-fit: contain;
        }
        p {
          text-align: justify;
        }
      }
      .quick-link {
        flex: 0.2;
        .links {
          margin-top: 20px;

          a {
            text-decoration: none;
            margin-top: 15px;
            color: white;
          }
        }
        h3 {
          color: $primary !important;
        }

        p {
          padding: 10px 0px;
          cursor: pointer;
          text-decoration: none;
        }
      }
      .contact-footer {
        flex: 0.3;
        h3 {
          color: $primary !important;
        }
        .location-detail {
          margin-top: 25px;
          h2 {
            margin-top: 15px;
            color: $primary;
          }
        }
      }
    }
    .bottom-footer {
    background-color: #6b6b6b ;
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      height: 10vh;
    }
    max-height: 50vh;
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .footer-container {
    padding-top: 30px;
    .top-footer {
      padding: 20px;

      background-color: #969696;

      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .about-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          height: 80px;
          object-fit: contain;
        }
      }
      .quick-link {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-direction: column;
        .links {
          margin-top: 20px;

          a {
            text-decoration: none;
            margin-top: 15px;
            color: white;
          }
        }
        h3 {
          color: $primary !important;
        }

        p {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 0px;
          cursor: pointer;
          text-decoration: none;
        }
      }
      .contact-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h3 {
          flex: 1;
          color: $primary !important;
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .location-detail {
          flex: 1;
          margin-top: 25px;
          h2 {
            margin-top: 15px;
            color: $primary;
          }
        }
      }
    }
    .bottom-footer {
    background-color: #6b6b6b ;
      display: flex;
      color: white;
      padding: 30px 0px;
      justify-content: center;
      align-items: center;
      height: 10vh;
    }
    max-height: 50vh;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .footer-container {
    padding-top: 30px;
    .top-footer {
      padding: 20px;

      background-color: #969696;

      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .about-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          height: 80px;
          object-fit: contain;
        }
        p {
          font-size: 14px;
        }
      }
      .quick-link {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-direction: column;
        .links {
          margin-top: 20px;

          a {
            text-decoration: none;
            margin-top: 15px;
            color: white;
          }
        }
        h3 {
          color: $primary !important;
        }

        p {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 0px;
          cursor: pointer;
          font-size: 14px;
          text-decoration: none;
        }
      }
      .contact-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h3 {
          flex: 1;
          color: $primary !important;
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
        .location-detail {
          flex: 1;
          margin-top: 25px;
          h2 {
            margin-top: 15px;
            color: $primary;
            font-size: 20px;
          }
        }
      }
    }
    .bottom-footer {
    background-color: #6b6b6b ;
      display: flex;
      color: white;
      padding: 30px 0px;
      justify-content: center;
      align-items: center;
      height: 10vh;
      p {
        font-size: 14px;
      }
    }
    max-height: 50vh;
    width: 100%;
  }
}
