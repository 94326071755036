$primary: #0099cc;
// .container {
//   display: flex;
//   width: 100%;
//   position: relative;
//   justify-content: space-between;
//   align-items: center;
//   .logo {
//     img {
//       width: 200px;
//       height: 60px;
//       object-fit: contain;
//       position: relative;
//     }
//   }
//   .nav-list {
//     ul {
//       display: flex;
//       list-style: none;
//       margin: auto;
//     }
//     ul li {
//       padding: 23px 25px;
//     }
//     ul li a {
//       color: rgb(63, 63, 63);
//       font-size: 16px;
//       text-decoration: none;
//       letter-spacing: 1;
//     }
//     .active {
//       padding: 23px 25px;
//       background-color: $primary;
//       a {
//         color: white;
//       }
//     }
//     .icons {
//       display: flex;
//       justify-content: center;
//       gap: 15px;
//       padding-right: 20px;
//       cursor: pointer;
//       .searchfield {
//         position: relative;
//         input {
//           position: absolute;
//           right: 100%;
//           top: 50%;
//           transform: translateY(-50%);
//           width: 0;
//           border: none;
//           transition: all 0.5s linear;
//         }
//         &.show {
//           input {
//             font-size: 16px;
//             width: 160px;
//             box-sizing: border-box;
//             border: none;
//             display: flex;
//             justify-content: center;
//             color: rgb(104, 104, 104);
//             border-radius: 15px;
//             background-color: rgba(238, 238, 238, 0.911);
//             // border-bottom: 1px solid rgb(158, 156, 156);
//             transition: all 0.5s linear;
//             padding-left: 15px;
//             padding-top: 5px;
//             padding-bottom: 5px;
//             font-size: 14px;
//             ::placeholder {
//               color: white;
//             }

//             &:focus {
//               border: none;
//               outline: 0;
//             }
//           }
//         }
//       }
//     }
//   }
//   .menu-list {
//     display: none;
//     position: absolute;
//   }
// }
// .btnLogin {
//   display: flex;
//   justify-content: flex-start;
//   gap: 15px;
//   cursor: pointer;
//   padding: 8px 15px;
//   color: white;
//   font-size: 16px;
//   background-color: $primary;
//   border: none;
//   width: 150px;
//   border-radius: 3px;
// }
// .btnRegister {
//   display: flex;
//   justify-content: flex-start;
//   gap: 15px;
//   cursor: pointer;
//   padding: 8px 15px;
//   color: white;
//   font-size: 16px;
//   width: 150px;
//   background-color: #414d52;
//   border: none;
//   border-radius: 3px;
// }
// @media screen and (max-width: 1110px) {
//   .container {
//     display: flex;
//     width: 100%;
//     position: relative;
//     justify-content: space-between;
//     align-items: center;
//     .logo {
//       flex: 0.3;
//       img {
//         width: 200px;
//         height: 60px;
//         object-fit: contain;
//         position: relative;
//       }
//     }
//     .nav-content {
//       display: flex;
//       justify-content: center;
//       flex-direction: row;
//       .nav-list {
//         display: none;
//         ul {
//           display: flex;
//           list-style: none;
//           margin: auto;
//         }
//         ul li {
//           padding: 23px 25px;
//         }
//         ul li a {
//           color: rgb(63, 63, 63);
//           font-size: 16px;
//           text-decoration: none;
//           letter-spacing: 1;
//         }
//         .active {
//           padding: 23px 25px;
//           background-color: $primary;
//           a {
//             color: white;
//           }
//         }
//       }
//       .icons {
//         display: none;
//         justify-content: center;
//         gap: 15px;
//         padding-right: 20px;
//         cursor: pointer;
//         .searchfield {
//           position: relative;
//           input {
//             position: absolute;
//             right: 100%;
//             top: 50%;
//             transform: translateY(-50%);
//             width: 0;
//             border: none;
//             transition: all 0.5s linear;
//           }
//           &.show {
//             input {
//               font-size: 16px;
//               width: 160px;
//               box-sizing: border-box;
//               border: none;
//               display: flex;
//               justify-content: center;
//               color: rgb(104, 104, 104);
//               border-radius: 15px;
//               background-color: rgba(238, 238, 238, 0.911);
//               // border-bottom: 1px solid rgb(158, 156, 156);
//               transition: all 0.5s linear;
//               padding-left: 15px;
//               padding-top: 5px;
//               padding-bottom: 5px;
//               font-size: 14px;
//               ::placeholder {
//                 color: white;
//               }

//               &:focus {
//                 border: none;
//                 outline: 0;
//               }
//             }
//           }
//         }
//       }
//     }
//     .menu-list {
//       display: block;
//       position: relative;
//     }
//   }
// }
// .btnLogin {
//   display: flex;
//   justify-content: flex-start;
//   gap: 15px;
//   cursor: pointer;
//   padding: 8px 15px;
//   color: white;
//   font-size: 16px;
//   background-color: $primary;
//   border: none;
//   width: 150px;
//   border-radius: 3px;
// }
// .btnRegister {
//   display: flex;
//   justify-content: flex-start;
//   gap: 15px;
//   cursor: pointer;
//   padding: 8px 15px;
//   color: white;
//   font-size: 16px;
//   width: 150px;
//   background-color: #414d52;
//   border: none;
//   border-radius: 3px;
// }
.navigation-bar {
  width: 100%;
  background-color: white;
  line-height: 2;
  letter-spacing: 0.4px;

  li,
  a {
    font-weight: 400;
    font-size: 16px;
    color: rgb(51, 50, 50);
    text-decoration: none;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    .icon {
      display: flex;
      justify-content: center;
      img {
        width: fit-content;
        height: 60px;
        object-fit: contain;
        position: relative;
      }
      @media screen and (max-width: 600px) {
        img {
          width: fit-content;
          height: 50px;
          object-fit: contain;
          position: relative;
        }
      }
      @media screen and (max-width: 300px) {
        img {
          width: fit-content;
          height: 43px;
          object-fit: contain;
          position: relative;
        }
      }
    }
    .menu-container {
      display: flex;
      max-width: 60%;
      height: fit-content;
      flex: 1;
      justify-content: space-between;

      @media screen and (max-width: 1110px) {
        display: none;
        position: absolute;
        background-color: #fff;
        flex-direction: column;
        z-index: 999;
        left: 0;
        right: 0;
        bottom: 0;
        top: 60px;
        &.show {
          display: block;
          max-width: 100%;
          .nav-list {
            display: flex;
            flex-direction: column;
            li {
              text-align: center;
            }
          }
          .icons {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            .search {
              display: none;
            }
            .searchfield {
              position: relative;
              input {
                position: relative;
                left: 0;
                transform: translateY(0%);
                width: 100%;
                border: none;
                transition: all 0.5s linear;
                font-size: 16px;
                width: 160px;
                box-sizing: border-box;
                border: none;
                display: flex;
                justify-content: center;
                color: rgb(104, 104, 104);
                border-radius: 15px;
                background-color: rgba(238, 238, 238, 0.911);
                // border-bottom: 1px solid rgb(158, 156, 156);
                transition: all 0.5s linear;
                padding-left: 15px;
                padding-top: 5px;
                padding-bottom: 5px;
                font-size: 14px;
                ::placeholder {
                  color: white;
                }

                &:focus {
                  border: none;
                  outline: 0;
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: 600px) {
        display: none;
        position: absolute;
        background-color: #fff;
        flex-direction: column;
        z-index: 999;
        left: 0;
        right: 0;
        bottom: 0;
        top: 47px;
        &.show {
          display: block;
          max-width: 100%;
          .nav-list {
            display: flex;
            flex-direction: column;
            li {
              text-align: center;
              padding: 12px 26px;
            }
            li a {
              font-size: 14px;
            }
          }
          .icons {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            .search {
              display: none;
            }
            .searchfield {
              position: relative;
              input {
                position: relative;
                left: 0;
                transform: translateY(0%);
                width: 100%;
                border: none;
                transition: all 0.5s linear;
                font-size: 14px;
                width: 160px;
                box-sizing: border-box;
                border: none;
                display: flex;
                justify-content: center;
                color: rgb(104, 104, 104);
                border-radius: 15px;
                background-color: rgba(238, 238, 238, 0.911);
                // border-bottom: 1px solid rgb(158, 156, 156);
                transition: all 0.5s linear;
                padding-left: 15px;
                padding-top: 5px;
                padding-bottom: 5px;
                font-size: 14px;
                ::placeholder {
                  color: white;
                }

                &:focus {
                  border: none;
                  outline: 0;
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: 300px) {
        display: none;
        position: absolute;
        background-color: #fff;
        flex-direction: column;
        z-index: 999;
        left: 0;
        right: 0;
        bottom: 0;
        top: 42px;
        &.show {
          display: block;
          max-width: 100%;
          .nav-list {
            display: flex;
            flex-direction: column;
            li {
              text-align: center;
              padding: 10px 26px;
            }
            li a {
              font-size: 12px;
            }
          }
          .icons {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            .search {
              display: none;
            }
            .searchfield {
              position: relative;
              input {
                position: relative;
                left: 0;
                transform: translateY(0%);
                width: 100%;
                border: none;
                transition: all 0.5s linear;
                font-size: 14px;
                width: 160px;
                box-sizing: border-box;
                border: none;
                display: flex;
                justify-content: center;
                color: rgb(104, 104, 104);
                border-radius: 15px;
                background-color: rgba(238, 238, 238, 0.911);
                // border-bottom: 1px solid rgb(158, 156, 156);
                transition: all 0.5s linear;
                padding-left: 15px;
                padding-top: 5px;
                padding-bottom: 5px;
                font-size: 14px;
                ::placeholder {
                  color: white;
                }

                &:focus {
                  border: none;
                  outline: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .icons {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    // #user {
    //   padding: 10px 20px;
    // }

    .searchfield {
      position: relative;
      input {
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        border: none;
        transition: all 0.5s linear;
      }
      &.show {
        input {
          font-size: 16px;
          width: 160px;
          box-sizing: border-box;
          border: none;
          display: flex;
          justify-content: center;
          color: rgb(104, 104, 104);
          border-radius: 15px;
          background-color: rgba(238, 238, 238, 0.911);
          // border-bottom: 1px solid rgb(158, 156, 156);
          transition: all 0.5s linear;
          padding-left: 15px;
          padding-top: 5px;
          padding-bottom: 5px;
          font-size: 14px;
          ::placeholder {
            color: white;
          }

          &:focus {
            border: none;
            outline: 0;
          }
        }
      }
    }
  }
  .nav-list {
    list-style: none;
  }

  .nav-list .active {
    background-color: $primary;
    a {
      color: white;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
  }
  .nav-list li {
    display: inline-block;
    padding: 16px 26px;
  }

  .nav-list li a {
    transition: all 0.3s ease 0s;
  }
  .menu-list {
    display: none;
  }
}
// .open-menu {
//   nav {
//     display: block;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0px 0px;
//   }
//   .open-nav-list {
//     display: block;
//     list-style: none;
//   }

//   .open-nav-list .active {
//     background-color: $primary;
//     a {
//       padding: 10px;
//       color: white;
//       font-weight: 500;
//     }
//   }
//   .nav-list li {
//     display: block;
//     padding: 16px 26px;
//   }

//   .nav-list li a {
//     transition: all 0.3s ease 0s;
//   }
// }
.dropdown-menu {
  padding: 20px !important;
  a {
    text-decoration: none;
  }
}
.btnLogin {
  display: flex;
  justify-content: center;
  gap: 15px;
  cursor: pointer;
  padding: 8px 15px;
  color: white;
  font-size: 16px;
  background-color: $primary;
  border: none;
  max-width: 150px;
  border-radius: 3px;
}
.btnRegister {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  cursor: pointer;
  padding: 8px 15px;
  color: white;
  font-size: 16px;
  width: 150px;
  background-color: #414d52;
  border: none;
  border-radius: 3px;
}
@media screen and (max-width: 1110px) {
  .navigation-bar {
    nav {
      padding: 0px 20px;
      .menu-list {
        display: flex;
        justify-content: center;
      }
    }
  }
}
@media screen and (max-width: 410px) {
  .navigation-bar {
    nav {
      padding: 0px 10px;
      .menu-list {
        display: flex;
        justify-content: center;
      }
    }
  }
}
