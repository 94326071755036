.form {
  width: 100%;
  padding: 50px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.container h1 {
  width: 100%;
  padding: 10px;
  text-decoration: underline;

  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.row {
  display: flex;
  width: 100%;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.inputs label {
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.inputs input,
.inputs textarea {
  display: flex;
  width: 100%;
  max-width: 480px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: black;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  background: #c0bebe;
  outline: none;
  border: none;
  resize: none;
}

.inputs textarea {
  max-width: none;
  height: 200px;
}

.inputs.imageInput div {
  width: 100%;
  max-width: 230px;
  height: 121px;
  background: #c0bebe;
  border-radius: 5px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.inputs.imageInput div input {
  padding: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  position: relative;
  cursor: pointer;
}

.inputs.imageInput div span {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #949494;
  z-index: 1;
}

.inputs.imageInput div span p {
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.inputs.imageInput div span svg {
  width: 30px;
  height: 30px;
}

.inputs .multipleInputs {
  width: 100%;
  position: relative;
}

.inputs .multipleInputs input {
  padding: 20px 35px;
}

.inputs .multipleInputs svg {
  position: absolute;
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: #210953;
  display: block;
}

.inputs .multipleInputs svg.add {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.inputs .multipleInputs svg.remove {
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.inputs .multipleInputs:nth-child(2) svg.remove {
  display: none;
}

.inputs .multipleInputs:last-child svg.add {
  display: block;
}

.container button {
  border-radius: 8px;
  background: #210953;
  display: flex;
  width: 100%;
  max-width: 480px;
  padding: 14px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: white;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 40px;
  height: 52px;
}
.container button:hover {
  background-color: #534670;
}

.container button:disabled {
  cursor: not-allowed;
  background-color: #949494;
}

.container button svg {
  animation: rotate 1s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}

.gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.gallery div {
  width: calc(50% - 10px);
  height: 100px;
  position: relative;
}

.gallery div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.gallery div svg {
  position: absolute;
  cursor: pointer;
  right: -9px;
  top: -6px;
}

.galleryInput {
  display: flex;
  width: 100%;
  max-width: 480px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: black;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  background: #c0bebe;
  position: relative;
}

.galleryInput input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

@media screen and (max-width: 650px) {
  .form {
    padding: 50px 16px;
  }
}

@media screen and (max-width: 500px) {
  .row {
    flex-direction: column;
  }
}
